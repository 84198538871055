<script>
	import { createEventDispatcher } from "svelte";
	import Button from "../control/Button.svelte";
	import TextArea from "../control/TextArea.svelte";
	import Modal from "../Modal.svelte";
	import { connection } from "../../stores/store";
	import Util from "../Util/Utility";

	const dispatch = createEventDispatcher();
	export let isUsedArtistDiscount;
	export let pin;
	export let promos;
	export let remarkPinStore;
	export let remarkStoreDiscount;
	export let storedata;
	export let storeDiscAmountValue;
	export let storeDiscount;
	export let subTotal;
	export let totalArtistDiscount;
	export let totalStoreDiscount;

	let errPin = false;
	let modalArtistDiscount;

	export function open() {
		modalArtistDiscount.toggle();
	}

	export function close() {
		modalArtistDiscount.close();
	}

	function validPinValue(target) {
		const input = target;
		const res =  Util.formatStorePin(input.value);
		// only allow numerical input and dot '.'.
		// .replace(/[^0-9.]/g, "")

		// only take the number after the first '.' 123.45.678 will be 123.45
		// .replace(/(\..*?)\..*/g, "$1");
		return res;
	}
	async function checkPinStore() {
		let result = false;
		await $connection.call("stores.byId.checkPin", storedata.id || storedata._id, pin).then((res) => {
			result = res;
		});

		return result;
	}
</script>

<Modal bind:this={modalArtistDiscount} index={20} withTitle={false}>
	<h4 class="mb-4 font-semibold">Artist Discount</h4>
	<div class="relative">
		<div class="flex-1">
			<label class="block text-primary text-sm font-bold mb-2" for="pin"> PIN* </label>
			<div class="flex">
				<input
					id="pin"
					name="pin"
					type="password"
					required
					placeholder="123456"
					maxlength="6"
					bind:value={pin}
					on:input={(e) => {
						const val = validPinValue(e.target);
						pin = val;
					}}
					class="appearance-none block w-full focus:ring-primary focus:border-primary text-sm text-dark-second border border-black rounded py-2 pl-3 pr-12 mb-2
			  {errPin ? 'border-red-500' : ''}"
				/>
			</div>
		</div>
	</div>
	<div class="my-4">
		<TextArea form={null} name="remarks" label="Remarks*" maxlength={200} bind:value={remarkPinStore} placeholder="" />
	</div>
	<div class="w-full flex space-x-2 justify-end">
		<Button
			on:click={() => {
				errPin = false;
				remarkPinStore = "";
				pin = "";
				isUsedArtistDiscount = false;
				modalArtistDiscount.close();
			}}
			status="gray">Cancel</Button
		>
		<Button
			size="large"
			status="success"
			on:click={async () => {
				errPin = false;
				if (remarkPinStore.length >= 10 && pin.length > 0) {
					const res = await checkPinStore();
					if (res) {
						isUsedArtistDiscount = true;
						totalArtistDiscount = subTotal;
						promos = [];
						storeDiscount = 0;
						remarkStoreDiscount = "";
						totalStoreDiscount = 0;
						storeDiscAmountValue = 0;
						globalThis.pushToast("Successfuly Add Discount Artist", "success");
						modalArtistDiscount.close();
					} else {
						isUsedArtistDiscount = false;
						errPin = true;
						globalThis.pushToast("PIN invalid", "error");
					}
				} else if (pin.length == 0) {
					isUsedArtistDiscount = false;
					errPin = true;
					globalThis.pushToast("Sorry! Please input PIN", "error");
				} else if (remarkPinStore.length < 10) {
					isUsedArtistDiscount = false;
					globalThis.pushToast("Sorry! Min 10 char Remarks", "error");
				}
				dispatch("submitArtistDiscount");
			}}>Ok</Button
		>
	</div>
</Modal>
