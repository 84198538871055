<script>
  import Util from "../../common/Util/Utility";
	import { paymentTypes } from "../../stores/store";

  export let data;
</script>

<div class="flex justify-end mb-2">
  <div
    class="border border-gray-200 bg-white rounded-md shadow-md w-2/3 flex flex-col p-4 space-y-2"
  >
    {#each data["items"] as product}
      <div class="flex items-center">
        <h4 class="text-md text-gray-800 font-medium w-3/4 break-words">
          ({product["amount"]})
          <span class="text-primary">{product["name"]}</span>
        </h4>
        <p class="text-md text-gray-800 font-medium w-1/4 text-right">
          {Util.formatRupiah(product["price"], "Rp. ")}
        </p>
      </div>
      {#if product["isAvailableProduct"] === false}
        <div class="mb-4 text-xs font-semibold text-red-700">
          Produk tidak aktif/tidak tersedia.
        </div>
      {/if}
      {#each product["additionals"] as additionals}
        {#each additionals["items"] as additional}
          <div>
            <div
              class="border-l-2 border-green-500 flex items-center justify-between pl-2 ml-2"
            >
              <h4 class="text-sm text-gray-800 font-medium">
                {additional["nameParent"] ? additional["nameParent"] : ""} - {additional[
                  "name"
                ]}
              </h4>
              <p class="text-md text-green-500">
                + {Util.formatRupiah(additional["price"], "Rp. ")}
              </p>
            </div>
            {#if additional["isAvailableModifier"] === false}
              <div class="ml-5 text-xs font-semibold text-red-700">
                Modifier tidak aktif/tidak tersedia.
              </div>
            {/if}
          </div>
        {/each}
      {/each}
      {#if product["toppings"].length > 0}
        {#each product["toppings"] as topping}
          <div
            class="border-l-2 border-green-500 flex items-center justify-between pl-2 ml-2"
          >
            <h4 class="text-sm text-gray-800 font-medium">
              {topping["name"]}
            </h4>
            <p class="text-md text-green-500">
              + {Util.formatRupiah(topping["price"], "Rp. ")}
            </p>
          </div>
        {/each}
      {/if}
    {/each}
    <hr class="my-3" />
    <div class="flex justify-between items-center">
      <h4 class="text-sm text-gray-800 font-medium">Subtotal</h4>
      <p class="text-md text-gray-800 font-medium">
        {Util.formatRupiah(data["subtotal"], "Rp. ")}
      </p>
    </div>
    <div class="flex justify-between">
      <h4 class="text-sm text-gray-800 font-medium">Promo Discount</h4>
      <div class="flex flex-col items-end">
        {#if data["promos"].length == 0}
          <p class="text-md text-red-500 font-medium">
            - {Util.formatRupiah(data["totalDiscount"], "Rp.")}
          </p>
        {/if}
      </div>
    </div>
    {#each data["promos"] as promo}
      {#each promo["used"] as promoUsed}
        <div class="flex justify-between">
          <h4
            class="text-sm text-gray-800 font-medium ml-3 pl-3 border-l-2 border-red-500"
          >
            {promo["name"]}
          </h4>
          <div class="flex flex-col items-end w-1/2">
            {#if promoUsed["bonus"].length == 0}
              {#if promoUsed["discount"] != 0}
                <p class="text-md text-red-500 font-medium">
                  - {Util.formatRupiah(parseInt(promoUsed["discount"]), "Rp.")}
                </p>
              {/if}
            {/if}
            {#if promoUsed["bonus"].length > 0}
              <div class="flex flex-col space-y-2 items-end">
                {#each promoUsed["bonus"] as bonus}
                  <p class="text-md text-green-500 font-medium break-words-self">
                    + Free {bonus["qty"]}
                    {bonus["name"]}
                  </p>
                {/each}
              </div>
            {/if}
          </div>
        </div>
      {/each}
    {/each}

    <div class="flex justify-between">
      <h4 class="text-sm text-gray-800 font-medium">Potongan Voucher</h4>
      <div class="flex flex-col items-end">
        {#if (data["voucher"] && data["voucher"].length == 0) || data["voucher"] == null}
          <p class="text-md text-red-500 font-medium">
            - {Util.formatRupiah(0, "Rp.")}
          </p>
        {/if}
      </div>
    </div>

    {#if data["voucher"]}
      {#each data["voucher"] as voucher}
        <div class="flex justify-between">
          <h4
            class="text-sm text-gray-800 font-medium ml-3 pl-3 border-l-2 border-red-500"
          >
            {voucher["name"]}
          </h4>
          <div class="flex flex-col items-end">
            {#if voucher["valueVoucher"] != 0}
              <p class="text-md text-red-500 font-medium">
                - {Util.formatRupiah(parseInt(voucher["valueVoucher"]), "Rp.")}
              </p>
            {/if}
          </div>
        </div>
      {/each}
    {/if}
    <div class="flex justify-between">
      <h4 class="text-sm text-gray-800 font-medium">Store Discount</h4>
      <div class="flex flex-col items-end">
        <p class="text-md text-red-500 font-medium">
          - {data["totalStoreDiscount"]
            ? Util.formatRupiah(parseInt(data["totalStoreDiscount"]), "Rp.")
            : "Rp. 0"}
        </p>
      </div>
    </div>
    <div class="flex justify-between">
      <h4 class="text-sm text-gray-800 font-medium">Artist Discount</h4>
      <div class="flex flex-col items-end">
        <p class="text-md text-red-500 font-medium">
          - {data["totalArtistDiscount"]
            ? Util.formatRupiah(parseInt(data["totalArtistDiscount"]), "Rp.")
            : "Rp. 0"}
        </p>
      </div>
    </div>
    <div class="flex justify-between items-center">
      <h4 class="text-sm text-gray-800 font-medium">Service Charge</h4>
      <p class="text-md text-gray-800 font-medium">
        {Util.formatRupiah(parseInt(data["serviceCharge"]), "Rp. ")}
      </p>
    </div>
    <div class="flex justify-between items-center">
      <h4 class="text-sm text-gray-800 font-medium">Tax</h4>
      <p class="text-md text-gray-800 font-medium">
        {Util.formatRupiah(parseInt(data["tax"]), "Rp. ")}
      </p>
    </div>
    <div class="flex justify-between items-center">
      <h4 class="text-sm text-gray-800 font-medium">Discount</h4>
      <p class="text-md text-red-500 font-medium">
        - {Util.formatRupiah(parseInt(data["fixDiscount"] || 0), "Rp. ")}
      </p>
    </div>
    <div class="flex justify-between items-center">
      <h4 class="text-sm text-gray-800 font-medium">Rounding</h4>
      <p
        class="text-md {data['roundingType'] == 'UP'
          ? 'text-gray-800'
          : 'text-red-500'} font-medium"
      >
        {data["roundingType"] == "UP"
          ? Util.formatRupiah(parseInt(data["rounding"]) || 0, "Rp. ")
          : "- " + Util.formatRupiah(parseInt(data["rounding"]) || 0, "Rp. ")}
      </p>
    </div>
    <hr class="my-3" />
    <div class="flex justify-between items-center mb-2">
      <h4 class="text-md text-gray-800 font-medium">Total</h4>
      <p class="text-md text-gray-800 font-medium">
        {Util.formatRupiah(data["total"], "Rp. ")}
      </p>
    </div>
    <div class="flex justify-between items-center">
      <h4 class="text-md text-gray-800 font-medium w-3/4 break-words">
        {Util.getPaymentTypeNameByCode($paymentTypes,data["paymentType"])}
      </h4>
      <p class="text-md text-gray-800 font-medium w-1/4 text-right">
        {Util.formatRupiah(data["paymentDetail"]["paymentValue"], "Rp. ")}
      </p>
    </div>
    <div class="flex justify-between items-center">
      <h4 class="text-md text-gray-800 font-medium">Kembalian</h4>
      <p class="text-md text-gray-800 font-medium">
        {Util.formatRupiah(
          data["employeeId"] == "BJWMOBILE"
            ? 0
            : data["paymentDetail"]["paymentValue"] - data["total"],
          "Rp. "
        )}
      </p>
    </div>
    {#if data["requestToVoid"]}
      <hr class="my-3" />
      <div class="flex flex-col">
        <h4 class="text-md text-gray-800 font-medium">Void Remarks</h4>
        <p class="text-md text-gray-800 break-words">
          {data["remarks"]}
        </p>
      </div>
    {/if}
  </div>
</div>
