<script>
  import Util from "../Util/Utility";
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  export let data;
</script>

<div class="relative mb-2">
  <div class="flex">
    <div
      class="flex-1 p-4 relative border border-gray-200 bg-white shadow-md rounded-tl-lg rounded-bl-lg cursor-pointer hover:bg-gray-100"
      on:click={() => {
        dispatch("click");
      }}
    >
      {#if data["requestToVoid"] && data["status"] != "VOIDED"}
        <div
          class="px-4 py-1 flex items-center rounded-lg bg-yellow-500 text-white mb-2 font-medium"
        >
					<i class="bx bx-error-circle mr-1" /> Requested Void
				</div>
			{/if}
			{#if data["requestChangePaymentType"]}
				<div class="px-4 py-1 flex items-center rounded-lg bg-yellow-500 text-white mb-2 font-medium">
					<i class="bx bx-error-circle mr-1" /> Requested to Change Payment Type
				</div>
			{/if}
			<div class="flex justify-between items-center">
				<h4 class="text-md font-medium text-gray-800">
					Transaction #{data["code"].substring(0, 8).toUpperCase()}
					{#if data["ojol"]}
            <span class="bg-green-500 rounded-md px-2 py-1 text-white text-xs">
              OJOL
            </span>
          {/if}

          {#if data["employeeId"] == "BJWMOBILE"}
            <span class="bg-blue-500 rounded-md px-2 py-1 text-white text-xs">
              MOBILE
            </span>
          {/if}
        </h4>
        <span class="text-sm text-gray-500"
          >{Util.formatDate(
            new Date(data["createdAtOnLocal"]),
            "long-detail"
          )}</span
        >
      </div>
      <div class="flex justify-between items-center">
        <h4 class="text-sm text-gray-800">
          Order No: <span class="font-medium"
            >{data["orderNo"] ? data["orderNo"] : "-"}</span
          >
        </h4>
      </div>
      <div class="flex justify-between items-center">
        <h4 class="text-sm text-gray-800">
          Status: <span
            class="font-medium {data['status'] != 'SUCCESS'
              ? 'text-red-500'
              : 'text-green-500'}">{data["status"]}</span
          >
        </h4>
      </div>
      <div class="flex justify-between items-center">
        <h4 class="text-sm text-gray-800">
          Items ({data["items"].reduce(
            (sumAdd, itemAdd) => sumAdd + itemAdd.amount,
            0
          )})
        </h4>
      </div>
      <div class="flex space-x-2 items-center">
        <i class="bx bx-user" />
        <h4 class="text-sm  text-gray-800">
          {data["customer"]["name"] ? data["customer"]["name"] : "-"}
        </h4>
      </div>
      <div class="flex space-x-2 items-center">
        <i class="bx bx-phone" />
        <h4 class="text-sm  text-gray-800">
          {data["customer"]["phone"] ? data["customer"]["phone"] : "-"}
        </h4>
      </div>
      <div class="flex space-x-2 items-center">
        <i class="bx bx-chair" />
        <h4 class="text-sm  text-gray-800">
          {data["customer"]["table"] ? data["customer"]["table"] : "-"}
        </h4>
      </div>
      <p
        class="absolute right-4 bottom-4 text-lg text-gray-800 font-medium m-0"
      >
        {Util.formatRupiah(data["total"], "Rp. ")}
      </p>
    </div>
    <!-- <div
									class="bg-gray-200 flex items-center justify-center px-4 hover:opacity-90 cursor-pointer"
									on:click="{() => {
										// handlePrint(data);
										// console.log(data);
										PrintyCo($store, data['items'], data, data['promos']);
										// push('/receipt/print');
									}}"
								>
									<i class="bx bx-printer text-2xl text-gray-800"></i>
								</div> -->
    <!-- button not available for transaction from mobile (gopay,shopeepay) -->
  {#if !data["requestToVoid"] && !data["requestChangePaymentType"] && data["employeeId"] != "BJWMOBILE"}
			<div
				class="bg-blue-500 text-white flex flex-col items-center justify-center px-4 hover:opacity-90 cursor-pointer font-semibold"
				on:click={() => {
					dispatch("changePaymentType");
				}}
			>
				<h4 class="text-center">
					<span class="block">CHANGE</span>
					<span class="block">PAYMENT</span>
					<span class="block">TYPE</span>
				</h4>
			</div>
		{/if}
		{#if !data["requestToVoid"] && !data["requestChangePaymentType"] 
			&& Util.formatDate(new Date(data["createdAtOnLocal"]), "short") == Util.formatDate(new Date(), "short")}
			<div
				class="bg-gray-600 text-white flex flex-col items-center justify-center px-4 hover:opacity-90 cursor-pointer font-semibold"
				on:click={() => {
					dispatch("void");
				}}
			>
				<h4>VOID</h4>
			</div>
		{/if}
		<div
			class="bg-primary rounded-tr-lg rounded-br-lg flex items-center justify-center px-4 hover:opacity-90 cursor-pointer"
			on:click={() => {
				dispatch("print");
			}}
		>
			<i class="bx bx-printer text-2xl text-white" />
		</div>
	</div>
</div>
