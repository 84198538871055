<script lang="ts">
	import Layout from "../common/Layout.svelte";
	import Loading from "../common/Loading.svelte";
	import { connection, objOfflineStatus, user } from "../stores/store";
	import AccountTemplate from "../templates/AccountTemplate.svelte";

	let _name;
	let _gender;
	let _image = "https://bit.ly/3pPY1jc";
	let modalLoading;
	user.subscribe((u) => {
		if (u) {
			_name = u.profile?.name;
			_gender = u.profile?.gender;
			_image = u.profile?.image ? u.profile?.image : "https://bit.ly/3pPY1jc";
		}
	});

	const updateUser = (e) => {
		const { fullname, gender, image } = e.detail;

		_name = fullname;
		_gender = gender;
		_image = image;

		if ($objOfflineStatus.status == "online") {
			$connection.call("users.update.profile", image, fullname, gender);
		}

		globalThis.pushToast("Successfully Update Account.", "success");
	};

	const updatePaymentTypeActiveInPos = (e) => {
		modalLoading.toggle();
		const { storeId, paymentTypeCode, status } = e.detail;
		if ($objOfflineStatus.status == "online") {
			$connection
				.call("stores.paymentType.update.activeInPos", storeId, paymentTypeCode, status)
				.then((_res) => {
					globalThis.pushToast("Successfully Changed Payment Method.", "success");
					modalLoading.close();
				})
				.catch((error) => {
					modalLoading.close();
					globalThis.pushToast("Something Wrong!.", "error");
					console.log(error);
				});
		}
	};
</script>

<Loading text="Loading..." bind:this={modalLoading} />

<Layout isFooterAbsolute={false}>
	<AccountTemplate
		bind:fullname={_name}
		gender={_gender}
		image={_image}
		on:updateUser={updateUser}
		on:updatePaymentTypeActiveInPos={updatePaymentTypeActiveInPos}
	/>
</Layout>
