<script lang="ts">
	import { createEventDispatcher } from "svelte";
	import { form as Form } from "svelte-forms";
	import { replace } from "svelte-spa-router";
	import MediaObject from "../common/DataDisplay/MediaObject.svelte";
	import Button from "../common/control/Button.svelte";
	import Input from "../common/control/Input.svelte";
	import InputSelect from "../common/control/InputSelect.svelte";
	import ExpandablePaymentType from "../common/ExpandablePaymentType.svelte";

	const dispatch = createEventDispatcher();

	export let fullname = "";
	export let image = "";
	export let gender = "";

	const form = Form(
		() => ({
			fullname: { value: fullname, validators: ["required"] },
			gender: { value: gender, validators: ["required"] },
		}),
		{
			initCheck: false,
			validateOnChange: false,
			stopAtFirstError: false,
			stopAtFirstFieldError: false,
		}
	);

	const handleSubmit = () => {
		form.validate();
		const valid = $form.valid;
		if (valid) {
			dispatch("updateUser", {
				fullname,
				gender,
				image,
			});
		}
	};
</script>

<div class="relative p-4 min-h-full">
	<div class="flex relative items-center z-10 cursor-pointer" on:click={() => replace("/")}>
		<i class="bx bxs-chevron-left text-3xl"></i>
		<h5 class="ml-2 text-md font-medium text-dark-second">Home</h5>
	</div>
  
	<div class="flex flex-wrap justify-center items-center">
    <div class="max-w-lg w-full flex flex-col justify-center items-center">
      <h4 class="text-left text-md font-medium text-dark-second w-full">Account</h4>
			<div class="border-2 border-black  rounded-lg w-full flex flex-col px-10 py-4">
				<MediaObject
					bind:image
					description="(jpg, png, jpeg)"
					size="medium"
					actionLabel="Upload Image"
					actionType="file"
					on:click={(e) => (image = e.detail)}
				/>
				<div class="my-2"></div>
				<Input name="fullname" bind:value={fullname} label="Fullname" {form} />
				<div class="my-2"></div>
				<InputSelect
					label="Gender"
					name="gender"
					{form}
					items={[
						{ id: "M", name: "Male" },
						{ id: "F", name: "Female" },
						{ id: "O", name: "Other" },
					]}
					bind:value={gender}
				/>
				<div class="my-2"></div>
				<Button on:click={handleSubmit} size="large">Save</Button>
			</div>

			<div class="my-2"></div>
			<p class="w-full text-left font-medium">POS Configuration</p>
			<ExpandablePaymentType
				on:changePaymentTypeActiveInPos={(e) => {
					dispatch("updatePaymentTypeActiveInPos", e.detail);
				}}
			/>
		</div>
	</div>
</div>
