<script>
	import { createEventDispatcher } from "svelte";
	import { excludedPaymentType, paymentTypeStatus } from "../enums/paymentType";
	import { paymentTypes, store } from "../stores/store";
	import Switch from "./control/Switch.svelte";
	import Util from "./Util/Utility";

	const dispatch = createEventDispatcher();
	let isExpand = false;
	const title = "Payment Type Configuration";
	function onClickItem() {
		if (!$paymentTypes || !$store) return;
		isExpand = !isExpand;
	}

	let mappedPaymentTypes;
	$: if ($paymentTypes && $store)
		mappedPaymentTypes = $paymentTypes
			.filter((e) => !excludedPaymentType.includes(e.code) && e.status == paymentTypeStatus.Active)
			.map((paymentType) => ({
				code: paymentType.code,
				name: paymentType.name,
				activeInPos: Util.resolveActiveInPosValue(paymentType, $store),
			}));
</script>

<div class="w-full place-items-center">
	<div class="transition">
		<div class={` bg-primary cursor-pointer border-b relative transition p-3 `} on:click={() => onClickItem()}>
			<div class="flex justify-between items-center text-white">
				<h3 class="font-medium">
					{title}
				</h3>
				<i class={`bx bx-chevron-${isExpand ? "down" : "up"}`} />
			</div>
		</div>
		{#if isExpand && $paymentTypes && $store}
			<div id="scrollbarSide" class="accordion-content-pos pt-0 max-h-sm overflow-y-auto border-2 border-black px-2">
				{#each mappedPaymentTypes as paymentType}
					<div class="flex justify-between">
						<p class="w-3/4 break-words-self">{paymentType.name}</p>
						<div class="flex items-center justify-center w-1/4">
							<Switch
								value={paymentType.activeInPos}
								checked={paymentType.activeInPos}
								notLabel
								textRight={paymentType.activeInPos ? paymentTypeStatus.Active : paymentTypeStatus.InActive}
								on:change={() => {
									dispatch("changePaymentTypeActiveInPos", {
										storeId: $store.id,
										paymentTypeCode: paymentType.code,
										status: !Util.resolveActiveInPosValue(paymentType, $store),
									});
								}}
							/>
						</div>
					</div>
					<hr class="border-gray-200" />
				{/each}
			</div>
		{:else}
			<div class="h-60 max-h-sm"></div>
		{/if}
	</div>
</div>

<style>
	.accordion-content {
		transition:
			max-height 0.3s ease-out,
			padding 0.3s ease;
	}
</style>
