<script lang="ts">
  import { addMinutes, format } from "date-fns";
  import cloneDeep from 'lodash/cloneDeep';
  import { createEventDispatcher, onMount } from "svelte";
  import { replace } from "svelte-spa-router";
  import Loading from "../common/Loading.svelte";
  import Modal from "../common/Modal.svelte";
  import Util from "../common/Util/Utility";
  import Printy from "../common/Util/print";
  import PrintyCO from "../common/Util/printCo";
  import CartCard from "../common/card/CartCard.svelte";
  import ProductCard from "../common/card/ProductCard.svelte";
  import TransactionCard from "../common/card/TransactionCard.svelte";
  import Button from "../common/control/Button.svelte";
  import MoneyInput from "../common/control/MoneyInput.svelte";
  import Switch from "../common/control/Switch.svelte";
  import TextArea from "../common/control/TextArea.svelte";
  import ModalArtistDiscount from "../common/modal/ModalArtistDiscount.svelte";
  import ModalOnlineOrder from "../common/modal/ModalOnlineOrder.svelte";
  import ModalPayment from "../common/modal/ModalPayment.svelte";
  import ModalProduct from "../common/modal/ModalProduct.svelte";
  import ModalScanBarcode from "../common/modal/ModalScanBarcode.svelte";
  import ModalStoreDiscount from "../common/modal/ModalStoreDiscount.svelte";
  import { storeDiscOption } from "../enums/discountType";
  import { modalProductOption } from "../enums/pos";
  import {
  	barcodeScan,
  	connection,
  	currUri,
  	inetStatus,
  	paymentTypes,
  	serverStatus,
  	store,
  	tempCart,
  	temporders as tempOr,
  	user
  } from "../stores/store";
	import { updatedProductActionType } from "../enums/action";
	import { paymentTypeStatus } from "../enums/paymentType";

  const dispatch = createEventDispatcher();
  let tooltipStoreDiscount = false;
  let tooltipVoucher = false;
  let tooltipPromoDiscount = false;
  let enableScan = true;
  let errorRequiredModifier = false;

  let storedata = $store;
  let storeProducts = [];
  /**
  * Contains products or menus data that is already processed.
  */
  let dataFiltered = [];
  /**
  * Contains menus data that is already processed.
  */
  let dataGrouped = [];
  let allMenuInActive = false;

  let isUsedArtistDiscount = false;

  $: orders = JSON.parse(localStorage.getItem("temporders")).filter(
    (ord) => ord && ord["data"]["items"].length > 0
  );

  store.subscribe((val) => {
    storedata = val;
    storeProducts =
      storedata.products != null &&
      storedata.products != undefined &&
      storedata.products.length > 0
        ? storedata.products.filter((product) => {
            // filter product that is active, available in POS, and the category is active
            if (
              product.activeInPos != undefined &&
              product.activeInPos != null
            ) {
              return (
                product.status == "ACTIVE" &&
                product.activeInPos == true &&
                product.category.status == "ACTIVE"
              );
            } else {
              return (
                product.status == "ACTIVE" &&
                product.category.status == "ACTIVE"
              );
            }
          })
        : [];

    if (storedata.menus && storedata.menus.length > 0) {
      const _newMenu = [];
      storedata.menus.forEach((menu, i) => {
        if (menu.status == "ACTIVE") {
          const newProducts = [];
          for (const menpro of menu.products) {
            // filter and find the menu's product in store's product
            const foundProduct = storeProducts
              .filter((pro) => {
                if (pro.activeInPos != undefined && pro.activeInPos != null) {
                  return (
                    pro.status == "ACTIVE" &&
                    pro.category.status == "ACTIVE" &&
                    pro.activeInPos == true
                  );
                } else {
                  return (
                    pro.status == "ACTIVE" && pro.category.status == "ACTIVE"
                  );
                }
              })
              .find((pro) => pro._id == menpro._id);
            
            // only push the product if it is available in the store
            if (foundProduct) {
              newProducts.push({
                ...menpro,
                ...foundProduct,
                activeInApp: foundProduct.activeInApp,
                activeInPos: foundProduct.activeInPos,
              });
            }
          }

          _newMenu.push({
            ...menu,
            products: newProducts,
          });
        }
      });

      allMenuInActive = _newMenu.findIndex((e) => e.status == "ACTIVE") == -1;
      dataGrouped = _newMenu.filter((e) => e.status == "ACTIVE");
      dataFiltered = !allMenuInActive ? dataGrouped : storeProducts;
    } else {
      allMenuInActive = true;
      dataFiltered = storeProducts.filter(
        (pro) => pro.category.status == "ACTIVE"
      );
    }
  });

  // product add
  /**
  * A temporary variable for a product that is going to be added/edited.
  * For additional and topping data, this will only contain the selected additional or topping.
  */
  $: item = null;
  /**
  * Current product being viewed on modal product. For additional and topping data
  * This will contain all of it's additional or topping data.
  */
  $: product = null;
  let disc = "";
  let titleModal = "";
  let searchKeyword = "";
  let searchOnlineOrders = "";
  $: codeScan = "";
  let customer = {
    _id: null,
    name: "",
    phone: null,
    table: "",
  };

  let typeModal = "online";
  let dataModal = [];
  let dataModalTemp = [];
  let dataModalSearch = [];
  let modalProduct;
  let modalConfirm;
  let modalScan;
  let modalTransactionScan;
  let modalPay;
  // let modalSummary;
  let modalConfirmLeave;
  let modalUpdateCart;
  let modalPrintCO;
  let modalNote;
  let modalStoreDiscount;
  let modalOnlineOrders;
  let modalConfirmToggle;
  let modalConfirmBarcode;
  let modalFixDiscount;
  let modalOnlineOrder;
  let modalArtistDiscount;
  let updatedCart = null;

  let remarkPinStore = "";
  let pin = "";
  let totalArtistDiscount = 0;
  /**
  * Contains the current time in Hours:Minutes:Seconds format.
  */
  let timeStr = "";
  let spvPassword = "";
  let isSpv = false;

  let onlineOrderTemp = null;

  let loadingConfirm = false;
  let ojolPrice = false;

  /**
  * Contains the value of store discount percentage.
  */
  let storeDiscount = 0;
  $: totalStoreDiscount =
    promoUsed && promoUsed.stores != null
      ? promoUsed.stores
          .reduce((sum, add) => sum + add.valueDiscount, 0)
          .toFixed(0)
      : 0;
  let remarkStoreDiscount = "";
  $: discValue = 0;
  $: discPercent = 0;
  $: lastMinutes = localStorage.getItem("lastMinutes");
  let mark = localStorage.getItem("mark");

  let tempTransaction = {};

  let fixDiscount = 0;
  let fixDiscountRemark = "";

  let voucher = null;

  $: cart = [];
  $: subTotal = cart.reduce(
    (sum, item) =>
      sum +
      (item.price +
        item.additionals.reduce(
          (sumAdd, itemAdd) => sumAdd + itemAdd.price,
          0
        ) +
        item.toppings.reduce((sumAdd, itemAdd) => sumAdd + itemAdd.price, 0)) *
        item.qty,
    0
  );

  let tooltipDiscountMap = [];
  let tooltipDiscountStoreMap = [];

  $: if (
    (promoUsed && promoUsed.promos != null) ||
    (promoUsed && promoUsed.stores != null)
  ) {
    tooltipDiscountMap = [];
    tooltipDiscountStoreMap = [];
    promoUsed.promos.forEach((item) => {
      if (
        item["discountType"] == "PERCENT" ||
        item["discountType"] == "AMOUNT"
      ) {
        item["products"].forEach((pro) => {
          const index = tooltipDiscountMap.findIndex(
            (x) => x["id"] == pro["id"]
          );
          const value =
            item["discountType"] == "PERCENT"
              ? ((item["subtotal"] / pro["qty"]) * (item["discount"] / 100)) /
                pro["allQty"]
              : item["discount"] / item["products"].length;

          if (index == -1) {
            tooltipDiscountMap.push({
              id: pro["id"],
              price: item["subtotal"],
              name: pro["name"],
              qty: pro["qty"],
              value: value,
            });
          } else {
            tooltipDiscountMap[index]["value"] += value;
            tooltipDiscountMap[index]["qty"] += pro["qty"];
          }
        });
      }
    });

    promoUsed.stores.forEach((st) => {
      const index = tooltipDiscountStoreMap.findIndex(
        (x) => x["id"] == st["id"]
      );
      if (index == -1) {
        tooltipDiscountStoreMap.push({
          id: st["id"],
          price: st["price"],
          name: st["name"],
          qty: st["qty"],
          value: st["valueDiscount"],
        });
      } else {
        tooltipDiscountStoreMap[index]["value"] += st["valueDiscount"];
        tooltipDiscountStoreMap[index]["qty"] += st["qty"];
      }
    });
  }

  $: totalSvpDiscount = 0;

  $: if (isSpv) {
    totalSvpDiscount = subTotal;
  }

  // discount
  $: priceNett =
    subTotal - discTotal - totalArtistDiscount - totalStoreDiscount;
  let taxValue = $store["tax"] != null ? $store["tax"] : 10;
  let chargeValue =
    $store["serviceCharge"] != null ? $store["serviceCharge"] : 15;
  $: serviceCharge =
    cart.length > 0 ? Math.round(priceNett * (chargeValue / 100)) : 0;
  $: taxTotal = Math.round((priceNett + serviceCharge) * (taxValue / 100));
  $: fixDiscountMap =
    fixDiscount == 0
      ? 0
      : Number.isNaN(fixDiscount)
        ? 0
        : Util.formatNumber(fixDiscount);
  $: tempTotalBeforeRounding = priceNett + taxTotal + serviceCharge;
  $: tempTotalBefore = tempTotalBeforeRounding - fixDiscountMap;
  $: rounding =
    cart.length == 0
      ? 0
      : $store["rounding"] != null && $store["rounding"] == "DOWN"
        ? Util.roundingDown(tempTotalBefore)
        : Util.roundingUp(tempTotalBefore);

  $: total =
    cart.length == 0
      ? 0
      : $store["rounding"] != null && $store["rounding"] == "DOWN"
        ? Math.round(tempTotalBefore - rounding)
        : Math.round(tempTotalBefore + rounding);

  $: promoUsed = null;
  $: discTotal =
    promoUsed && promoUsed.promos
      ? promoUsed.promos
          .filter((x) => x.discountType != "BONUS")
          .reduce((sum, add) => sum + add.discountValue, 0)
          .toFixed(0)
      : 0;

  // paymentType
  $: paymentType = "CASH";
  $: paymentValue = 0;
  $: paymentDetail = {
    name: "",
    numberCard: null,
    cvv: null,
    expDate: null,
  };

  $: promos = JSON.parse(localStorage.getItem("promos") || "[]");

  $: campaign = JSON.parse(localStorage.getItem("campaign") || "[]");
  $: tempCampaign = [];

  let bonusProduct = [];
  let supplies = [];
  let tempIng = [];

  let totalVoucher = 0;

  $: if (!!isUsedArtistDiscount) {
    totalArtistDiscount = subTotal;
    promos = [];
    storeDiscount = 0;
    remarkStoreDiscount = "";
    totalStoreDiscount = 0;
    storeDiscAmountValue = 0;
  }

  let selectedStoreDiscType = storeDiscOption[0].value;
  let storeDiscAmountValue = 0;
  /**
  * Contains the value of store discount in IDR.
  */
  $: storeDiscAmountValueMap =
    storeDiscAmountValue == 0
      ? 0
      : Number.isNaN(storeDiscAmountValue)
        ? 0
        : Util.formatNumber(storeDiscAmountValue);

  let loadingSubmitPay = false;

  let fromScanCode = false;

  onMount(() => {
    $currUri = window.location.hash;
    currUri.set(window.location.hash);
    setInterval(() => {
      timeStr = new Date().toLocaleTimeString("en-US", {
        timeZone: "Asia/Jakarta",
      });
    }, 1000);
  });

  const handlePromoUsed = () => {
    if (cart.length == 0) {
      isUsedArtistDiscount = false;
      totalArtistDiscount = 0;
      remarkPinStore = "";
      pin = "";
      storeDiscount = 0;
      remarkStoreDiscount = "";
      totalStoreDiscount = 0;
      storeDiscAmountValue = 0;
      selectedStoreDiscType = "PERCENT";
    }

    cart = cart.map((c) => {
      const allQty = cart
        .filter((y) => y.id == c.id)
        .reduce((sum, item) => sum + item["qty"], 0);
      return {
        ...c,
        allQty,
      };
    });

    let tempArrCart = [];
    cart.map((c) => {
      for (let i = 0; i < c.qty; i++) {
        tempArrCart.push({
          ...c,
          qty: 1,
        });
      }
    });

    promos = !!isUsedArtistDiscount
      ? [] // promo is not available if artist discount in on use
      : JSON.parse(localStorage.getItem("promos") || "[]");

    let isStoreDiscPercent = selectedStoreDiscType == "PERCENT";
    let usedStoreDisc = isStoreDiscPercent
      ? storeDiscount
      : storeDiscAmountValueMap;
    let isStoreDisc100 = isStoreDiscPercent // true if discount is a full discount
      ? storeDiscount == 100
      : storeDiscAmountValueMap == subTotal;
    if (promos.length > 0) {
      promoUsed = Util.handlePromoUsedNew(
        promos,
        tempArrCart,
        usedStoreDisc,
        isStoreDiscPercent,
        isStoreDisc100
      );
    } else {
      promoUsed = Util.handlePromoUsedNew(
        [],
        tempArrCart,
        usedStoreDisc,
        isStoreDiscPercent,
        isStoreDisc100
      );
    }

    // promoUsed.stores contains products that is not included in promo
    // if there is nothing in promoUsed.stores, reset the store discount variable
    // also only reset variable if cart is not empty, because variable reset is done above when cart is empty
    if (promoUsed.stores.length == 0 && cart.length > 0) {
      storeDiscount = 0;
      remarkStoreDiscount = "";
      totalStoreDiscount = 0;
      storeDiscAmountValue = 0;
      selectedStoreDiscType = "PERCENT";
    }

    tempIng = [];
    cart.forEach((f) => {
      f["toppings"].forEach((t) => {
        t["ingredients"].forEach((ing) => {
          const exTempIng = tempIng.findIndex(
            (x) => x["_id"] == ing["_id"] && x["productId"] == t["_id"]
          );
          if (exTempIng == -1) {
            const ingTemp = {
              ...ing,
              productId: t["_id"],
              total: ing["measure"],
            };
            tempIng.push(ingTemp);
          } else {
            tempIng[exTempIng]["total"] =
              tempIng[exTempIng]["total"] + ing["measure"] * f["qty"];
          }
        });
      });
      f["ingredients"].forEach((ing) => {
        const exTempIng = tempIng.findIndex(
          (t) => t["_id"] == ing["_id"] && t["productId"] == f["id"]
        );
        if (exTempIng == -1) {
          const ingTemp = {
            ...ing,
            productId: f["id"],
            total: ing["measure"] * f["allQty"],
          };
          tempIng.push(ingTemp);
        } else {
          tempIng[exTempIng]["total"] = ing["measure"] * f["allQty"];
        }
      });
    });
    tempIng = tempIng;

    // processing campaign data
    tempCampaign = [];
    if (campaign.length > 0 && !isStoreDisc100) {
      const activeCampaigns = campaign.filter((f) => f["status"] == "ACTIVE");

      const campaigns = activeCampaigns.map((campaign) => {
        const founds = campaign.products.filter((prod) =>
          cart.find((it) => it["id"] == prod["_id"]) // find campaign that it's product exists in cart
        );
        if (founds.length > 0) {
          // sum all of the contribution from items in the cart
          const contribution = founds
            .map(
              (prod) =>
                prod.contribution *
                cart.find((it) => it["id"] == prod["_id"]).allQty
            )
            .reduce((a, b) => a + b);
          return { ...campaign, ...{ contribution } };
        }
        return;
      });
      tempCampaign = campaigns // temp campaign will be dispatched later
        .filter((s) => s !== undefined)
        .map(
          ({ id, name, products, totalContribution, image, contribution }) => {
            return {
              id,
              name,
              products,
              totalContribution,
              image,
              contribution,
            };
          }
        );
    }
  };

  const handleAddItem = (product, from = null, additionals = []) => {
    if (titleModal === modalProductOption.Add.title) {
      // handle ingredients supply check
      // const enough = handleIngredients(product);
      errorRequiredModifier = Util.isRequiredModifierError(product, additionals);
      if (from == "pos" && errorRequiredModifier) {
        return;
      }

      const enough = true;
      if (enough) {
        cart = [...cart, product];

        handlePromoUsed();
        clear();
        if (from == "pos") {
          modalProduct.close();
        }
      } else {
        globalThis.pushToast("Sorry! Not Enough Stock.", "error");
      }
    }
  };

  const handleEditItem = (product, additionals = []) => {
    errorRequiredModifier = Util.isRequiredModifierError(product, additionals);
    if (errorRequiredModifier) return;
    // remove the border on edit button and error message.
    // need forEach because a product can exist more than 1 in cart.
    cart.forEach((crt) => {
      const isFoundProduct = crt.id == product.id;
      if (isFoundProduct) {
        crt.isErrorRequiredModifier = false;
      }
    });
    cart = [...cart];

    titleModal = "";
    item = null;
    modalProduct.close();
  };

  const handleIngredients = (product, from?) => {
    const existProduct = cart.findIndex((c) => c.id == product.id);
    const enough = product["ingredients"].every((ing) => {
      const indexSup = supplies.findIndex((s) => s["itemId"] == ing["_id"]);
      const indexIng = tempIng.findIndex(
        (t) => t["_id"] == ing["_id"] && t["productId"] != product["id"]
      );
      if (indexSup != -1) {
        let qtyWithCart;
        let totalIngTemp = 0;
        if (titleModal === modalProductOption.Add.title) {
          qtyWithCart =
            existProduct != -1
              ? cart[existProduct]["allQty"] + product["qty"]
              : product["qty"];
        } else {
          qtyWithCart = product["qty"];
        }
        const tempTotalMeasure =
          ing["measure"] *
          (from == "plus"
            ? qtyWithCart + 1
            : from == "minus"
              ? qtyWithCart - 1
              : qtyWithCart);

        if (indexIng != -1) {
          const allTotalMeasure = tempIng
            .filter(
              (t) => t["_id"] == ing["_id"] && t["productId"] != product["id"]
            )
            .reduce((a, b) => a + b.total, 0);
          totalIngTemp = allTotalMeasure + tempTotalMeasure;
        } else {
          totalIngTemp = tempTotalMeasure;
        }
        if (supplies[indexSup]["total"] >= totalIngTemp) {
          return true;
        }
        return false;
      }
      return false;
    });

    let ingredientsToppings = [];
    if (product["toppings"].length > 0) {
      product["toppings"].forEach((e) => {
        e["ingredients"].forEach((i) => {
          ingredientsToppings = [...ingredientsToppings, i];
        });
      });
    }

    const enoughTopping = ingredientsToppings.every((ing) => {
      const indexSup = supplies.findIndex((s) => s["itemId"] == ing["_id"]);
      const indexIng = tempIng.findIndex(
        (t) => t["_id"] == ing["_id"] && t["productId"] != product["id"]
      );
      if (indexSup != -1) {
        let qtyWithCart;
        let totalIngTemp = 0;
        if (titleModal === modalProductOption.Add.title) {
          qtyWithCart =
            existProduct != -1
              ? cart[existProduct]["qty"] + product["qty"]
              : product["qty"];
        } else {
          qtyWithCart = product["qty"];
        }
        const tempTotalMeasure =
          ing["measure"] *
          (from == "plus"
            ? qtyWithCart + 1
            : from == "minus"
              ? qtyWithCart - 1
              : qtyWithCart);

        if (indexIng != -1) {
          const allTotalMeasure = tempIng
            .filter(
              (t) => t["_id"] == ing["_id"] && t["productId"] != product["id"]
            )
            .reduce((a, b) => a + b.total, 0);
          totalIngTemp = allTotalMeasure + tempTotalMeasure;
        } else {
          totalIngTemp = tempTotalMeasure;
        }
        if (supplies[indexSup]["total"] >= totalIngTemp) {
          return true;
        }
        return false;
      }
      return false;
    });

    if (enough === enoughTopping) {
      return true;
    }

    return false;
  };

  const handleAddAdditional = (from, add) => {
		const posData = {
			titleModal,
			item : cloneDeep(item),
			cart : cloneDeep(cart),
		};
		const handleAddAdditionalResult = Util.handleAddAdditional(from, add, posData);
    item = handleAddAdditionalResult.itemResult
    if(handleAddAdditionalResult.callPromoUsed) {
      cart = handleAddAdditionalResult.cartResult;
		  handlePromoUsed();
    }
	};

  /**
  * This function handles reducing the quantity of a product in the cart by 1
  */
  const minusItem = (product, from?) => {
    if (titleModal == modalProductOption.Add.title) {
      if (item.qty > 1) {
        item.qty -= 1;
        item = item;
      } else {
        // send an alert when reducing quantity to 0 when adding a product to cart
        alert("minimal qty: 1"); 
      }
    } else {
      for (let itemCart of cart) {
        if (itemCart.idCart === product.idCart) {
          if (product.qty > 1) {
            // const check = handleIngredients(product, "minus");
            const check = true;
            if (!check) {
              globalThis.pushToast("Sorry! Not Enough Stock.", "error");
            } else {
              itemCart.qty -= 1;
              cart = cart;
              item = itemCart;
            }
          } else {
            // when decreasing the quantity from 1 to 0 when editing a cart's product
            // toggle a modal to confirm delete product from cart
            if (from == "edit") {
              modalProduct.close();
            }
            showConfirm(product);
          }
        }
      }
    }
    handlePromoUsed();
  };

  const deleteItem = (product) => {
    cart = cart.filter((cartItem) => cartItem.idCart != product.idCart);

    handlePromoUsed();
    clear();
  };

  /**
  * This function handles increasing the quantity of a product in the cart by 1
  */
  const plusItem = (product) => {
    if (titleModal == modalProductOption.Add.title) {
      item.qty += 1;
      item = item;
      return;
    } else {
      for (let itemCart of cart) {
        if (itemCart.idCart === product.idCart) {
          // const check = handleIngredients(product, "plus");
          const check = true;
          if (!check) {
            globalThis.pushToast("Sorry! Not Enough Stock.", "error");
          } else {
            itemCart.qty += 1;
            cart = cart;
            item = itemCart;
          }
        }
      }
    }
    handlePromoUsed();
  };

  /**
  * This function handles toggling the modal for adding/editing a cart's product
  */
  const showModal = async (productItem, title) => {
    titleModal = title;
    const priceSelected = ojolPrice
      ? productItem.priceOnline ?? 0
      : productItem.price;
    if (titleModal === modalProductOption.Add.title) {
      const itemAdd = {
        idCart: Math.random(),
        id: productItem._id,
        qty: 1,
        price: priceSelected,
        name: productItem.name,
        images: productItem.images,
        ingredients: productItem.ingredients,
        additionals: [],
        toppings: [],
        notes: "",
      };
      item = itemAdd;

      let newTop = [];
      productItem.toppings.forEach((top, indexTop) => {
        let ingFilter = [];
        if (top.ingredients && top.ingredients.length > 0) {
          top.ingredients.forEach((ing, indexIng) => {
            const outOfstock = supplies.filter(
              (sup) =>
                sup["itemId"] == ing["_id"] && ing["measure"] > sup["total"]
            ).length;
            if (outOfstock > 0) {
              ingFilter.push(indexTop);
            }
          });
        }

        const newTopping = {
          ...top,
          available: true,
        };

        if (top.status == "ACTIVE") {
          newTop.push(newTopping);
        }
      });
      product = {
        ...productItem,
        toppings: newTop,
      };
    } else {
      console.log($serverStatus && $inetStatus ? "IS ONLINE" : "IS OFFLINE");
      // check is online
      if ($serverStatus && $inetStatus) {
        // get data product
        // loadingModalProduct = true;
        const productData = await $connection.call("stores.getProduct.forPOS", {
          storeId: storedata.id || storedata._id,
          productId: productItem.id,
        });

        const foundProductInActiveMenu = productData.menus.find(
          (pro) => pro.productId == productItem.id
        );

        // validate product in inactive menu
        if (!!productData.hasActiveMenu && !foundProductInActiveMenu) {
          deleteItem(productItem);
          globalThis.pushToast(
            "Product di remove dari cart karena Menu dari Product sudah tidak aktif",
            "error"
          );
          return;
        }

        // validate product active
        if (
          productData.product.status == "INACTIVE" ||
          !productData.product.activeInPos
        ) {
          deleteItem(productItem);
          globalThis.pushToast(
            "Product di remove dari cart karena sudah tidak aktif",
            "error"
          );
          return;
        }

        // validate product category active
        if (productData.product.category.status == "INACTIVE") {
          deleteItem(productItem);
          globalThis.pushToast(
            "Product di remove dari cart karena Category dari Product sudah tidak aktif",
            "error"
          );
          return;
        }

        // merge data
        updateEditProduct(productData.product);
        updateCartEditProduct(productData.product);
        updateModalProduct(productItem, productData.product);
      } else {
        // is offline
        const index = storeProducts.findIndex((element, index) => {
          if (element._id === productItem.id) {
            return true;
          }
        });
        updateEditProduct(storeProducts[index]);
        updateCartEditProduct(storeProducts[index]);
        updateModalProduct(productItem, storeProducts[index]);
      }
    }

    modalProduct.open();
  };

   /**
  * This function update a product's data that is currently being viewed on modal product
  */
  const updateEditProduct = (_product) => {
    // update topping
    let newTop = [];
    _product["toppings"].forEach((top, indexTop) => {
      let ingFilter = [];
      if (top.ingredients && top.ingredients.length > 0) {
        top.ingredients.forEach((ing, indexIng) => {
          const outOfstock = supplies.filter(
            (sup) =>
              sup["itemId"] == ing["_id"] && ing["measure"] > sup["total"]
          ).length;
          if (outOfstock > 0) {
            ingFilter.push(indexTop);
          }
        });
      }

      const newTopping = {
        ...top,
        available: true,
      };

      if (top.status == "ACTIVE") {
        newTop.push(newTopping);
      }
    });

    // update modifier
    let newMods = [];
    _product.additionals.forEach((parent) => {
      if (parent.status == "ACTIVE") {
        const newChild = parent.items.filter(
          (child) => !!child.active && !!child.activeInPos
        );

        if (newChild.length > 0) {
          newMods.push({
            ...parent,
            items: newChild,
          });
        }
      }
    });

    // merge all
    product = {
      ..._product,
      toppings: newTop,
      additionals: newMods,
    };
  };

   /**
  * This function update a cart item's data that is currently being viewed on modal product
  */
  const updateCartEditProduct = (_productData) => {
    const flattenMods = getFlattenModsFromProduct(_productData);

    cart = cart.map((_cart) => {
      if (_cart.id == _productData.id) {
        return {
          ..._cart,
          name: _productData.name,
          price: ojolPrice ? _productData.priceOnline : _productData.price,
          toppings: _productData.toppings?.filter((top) => {
            const foundTopInCart = _cart.toppings?.find(
              (cartTop) => cartTop._id == top._id
            );

            return top.status == "ACTIVE" && !!foundTopInCart;
          }),
          additionals: _cart.additionals
            .filter((mod) => {
              const foundModInData = flattenMods.find(
                (cmpMod) =>
                  cmpMod.parentId == mod.parentId && cmpMod.childId == mod.code
              );

              if (!foundModInData) return false;

              return (
                foundModInData.parentStatus == "ACTIVE" &&
                !!foundModInData.childActive &&
                !!foundModInData.childActiveInPos
              );
            })
            .map((mod) => {
              const foundModInData = flattenMods.find(
                (cmpMod) =>
                  cmpMod.parentId == mod.parentId && cmpMod.childId == mod.code
              );

              return {
                ...mod,
                nameParent: foundModInData.parentName,
                name: foundModInData.childName,
                price: foundModInData.childPrice,
              };
            }),
        };
      }

      return _cart;
    });
  };

    /**
  * This function update the "item" data that matches with the product that is being viewed on modal product
  */
  const updateModalProduct = (_cartData, _productData) => {
    const priceSelected = ojolPrice
      ? _productData.priceOnline ?? 0
      : _productData.price;

    const flattenMods = getFlattenModsFromProduct(_productData);

    item = {
      ..._cartData,
      price: priceSelected,
      name: _productData.name,
      toppings: _cartData.toppings.map((top) => {
        const foundTopInData = _productData.toppings.find(
          (topProd) => topProd._id == top._id
        );
        if (foundTopInData) {
          return {
            ...top,
            name: foundTopInData.name,
            price: foundTopInData.price,
          };
        }

        return top;
      }),
      additionals: _cartData.additionals.map((mod) => {
        const foundModInData = flattenMods.find(
          (cmpMod) =>
            cmpMod.parentId == mod.parentId && cmpMod.childId == mod.code
        );

        if (foundModInData) {
          return {
            ...mod,
            name: foundModInData.childName,
            nameParent: foundModInData.parentName,
            price: foundModInData.childPrice,
          };
        }

        return mod;
      }),
    };
  };

  const getFlattenModsFromProduct = (_productData) => {
    const flattenMods = [];
    _productData.additionals.forEach((parent) => {
      parent.items.forEach((child) => {
        flattenMods.push({
          parentId: parent._id,
          parentName: parent.name,
          parentStatus: parent.status,
          childId: child.code,
          childName: child.name,
          childActive: child.active,
          childActiveInApp: child.activeInApp,
          childActiveInPos: child.activeInPos,
          childPrice: child.price,
        });
      });
    });
    return flattenMods;
  };

  const clear = () => {
    item = null;
    titleModal = "";

    if (cart.length == 0) {
      customer = {
        _id: null,
        name: "",
        phone: null,
        table: "",
      };
      fromScanCode = false;
      barcodeScan.set(null);
      fixDiscount = 0;
      fixDiscountRemark = "";
    }
  };

  // Toggle a modal to confirm for delete product
  const showConfirm = (itemProduct) => {
    item = itemProduct;
    modalConfirm.toggle();
  };

  let timer;

  const debounce = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      search();
    }, 1000);
  };

  const search = () => {
    const keyword =
      searchKeyword.trim().toLowerCase() ||
      searchOnlineOrders.trim().toLowerCase();

    if (searchOnlineOrders.length > 0) {
      let dataModalFiltered = dataModal.filter((item) => {
        if (
          item.code.toLowerCase().includes(keyword) ||
          (item["customer"]["phone"] &&
            item["customer"]["phone"].toLowerCase().includes(keyword)) ||
          item.orderNo.toLowerCase().includes(keyword)
        )
          return true;
        return false;
      });

      dataModalSearch = dataModalFiltered;
      dataModalTemp = dataModalSearch;
    } else {
      dataModalSearch = [];
      dataModalTemp = dataModal;
    }

    if (searchOnlineOrders.length == 0) {
      let itemFiltered = [];
      if (!allMenuInActive) {
        dataGrouped.forEach((menu) => {
          let newProducts = [];
          menu.products.forEach((prod) => {
            if (
              prod.name.toLowerCase().includes(keyword) ||
              prod.sku.toLowerCase().includes(keyword)
            ) {
              newProducts.push(prod);
            }
          });
          if (newProducts.length > 0) {
            itemFiltered.push({ ...item, products: newProducts });
          }
        });
      } else {
        itemFiltered = storeProducts.filter((item) => {
          if (
            item.name.toLowerCase().includes(keyword) ||
            item.sku.toLowerCase().includes(keyword)
            // ||
          )
            return true;
          return false;
        });
      }

      dataFiltered = [...itemFiltered];
    }
  };

  const showScan = () => {
    if (ojolPrice && cart.length > 0) {
      modalConfirmBarcode.toggle();
    } else {
      ojolPrice = false;
      modalScan.open();
    }
    // reader.listVideoInputDevices().then((videoInputDevices) => {
    //   selectedDeviceId = videoInputDevices[0].deviceId;
    // });

    // reader.decodeFromVideoDevice(selectedDeviceId, "video", (result, err) => {
    //   if (result) {
    //     codeScan = "";
    //     codeScan = result["text"];
    //     generateTransaction(codeScan);
    //     // search();
    //     reader.reset();
    //     reader.stopAsyncDecode();
    //     reader.stopContinuousDecode();
    //     modalScan.toggle();
    //   }
    //   if (err && !(err instanceof NotFoundException)) {
    //     console.error(err);
    //   }
    // });
  };

  const generateTransaction = (code) => {
    let transaction = orders.filter((ord) => ord["code"] == code);
    if (transaction.length > 0) {
      tempTransaction = {};
      tempTransaction = { data: transaction[0] };
      modalTransactionScan.toggle();
    } else {
      globalThis.pushToast("Sorry! Transaction not found.", "error");
    }
  };


  const resetCartError = () => {
    cart.forEach((crt) => {
      crt.isErrorProduct = false;
      crt.isErrorMenu = false;
      crt.additionals.forEach((item) => {
        item.isErrorModifier = false;
      });
      crt.toppings.forEach((item) => {
        item.isErrorTopping = false;
      });
    });
  };

  const validateCart = async () => {
    // validate api
    loadingSubmitPay = true;
    resetCartError();

    const isValid = await $connection.call("stores.validateCart.forPOS", {
      storeId: storedata.id || storedata._id,
      items: Util.createItemsSubmit(cart),
      isOjolPrice: ojolPrice,
    });

    if (!isValid.success) {
      if (isValid.error == "object-not-valid") {
        globalThis.pushToast("Sorry! Please try again in few seconds", "error");
        return false;
      }

      if (isValid.error.includes("required-modifier-not-selected")) {
        cart.forEach((crt) => {
          const foundErrorProduct = isValid.errorModifierRequired.find(
            (error) => error.productId === crt.id
          );
          if (
            foundErrorProduct &&
            !crt.additionals.find(
              (adds) => adds.parentId == foundErrorProduct.additionalId
            )
          ) {
            crt.isErrorRequiredModifier = true;
          }
        });
        dataFiltered.forEach((product) => {
          const foundErrorProduct = isValid.errorModifierRequired.find(
            (error) => error.productId === product._id
          );
          if (foundErrorProduct) {
            product.additionals.forEach((adds) => {
              const foundRequiredItem = isValid.errorModifierRequired.find(
                (error) => error.additionalId === adds._id
              );
              if (foundRequiredItem) adds.isRequired = true;
            });
          }
        });
        globalThis.pushToast("Pilih Modifier Wajib terlebih dahulu.", "error");
      }

      if (isValid.error.includes("product-not-available")) {
        cart.forEach((crt) => {
          const foundProd = isValid.errorProductNotAvail.find(
            (errIt) => errIt._id == crt.id
          );
          if (foundProd) {
            crt.isErrorProduct = true;
          }
        });
        globalThis.pushToast("Sorry! please check your Product again", "error");
      }

      if (isValid.error.includes("menu-not-available")) {
        cart.forEach((crt) => {
          const foundProd = isValid.errorMenuNotAvail.find(
            (errIt) => errIt._id == crt.id
          );
          if (foundProd) {
            crt.isErrorMenu = true;
          }
        });
        globalThis.pushToast("Sorry! please check your Menu again", "error");
      }

      if (isValid.error.includes("modifier-not-available")) {
        cart.forEach((crt) => {
          crt.additionals.forEach((item) => {
            const foundAdd = isValid.errorModifierNotAvail.find(
              (errIt) =>
                errIt.parentId == item.parentId && errIt.code == item.code
            );

            if (foundAdd) {
              item.isErrorModifier = true;
            }
          });
        });
        globalThis.pushToast(
          "Sorry! please check your Modifier again",
          "error"
        );
      }

      if (isValid.error.includes("topping-not-available")) {
        cart.forEach((crt) => {
          crt.toppings.forEach((item) => {
            const foundTop = isValid.errorToppingNotAvail.find(
              (errIt) => errIt._id == item._id
            );

            if (foundTop) {
              item.isErrorTopping = true;
            }
          });
        });
        globalThis.pushToast("Sorry! please check your Topping again", "error");
      }

      if (isValid.error.includes("product-change")) {
        updatedCart = isValid.products;
        onCloseModalPay();
        modalUpdateCart.toggle();
      }

      return false;
    }


    const usedPaymentType = $paymentTypes.find((payment) => payment.code == paymentType);
		if (!usedPaymentType || (usedPaymentType.status == paymentTypeStatus.InActive || !Util.resolveActiveInPosValue(usedPaymentType, $store))) {
			globalThis.pushToast("Payment Method is Inactive", "error");
			return false;
		}
		return true;
  };

  const onUpdateCartAfterChange = () => {
    updatedCart.forEach((updCart) => {
      const foundInCart = cart.find((cartIt) => cartIt.id == updCart.id);

      if (foundInCart) {
        // validate product active
        if (updCart.status == "INACTIVE" || !updCart.activeInPos) {
          deleteItem(foundInCart);
        }

        // merge data
        updateEditProduct(updCart);
        updateCartEditProduct(updCart);
        updateModalProduct(foundInCart, updCart);
      }
    });
    globalThis.pushToast("Cart Updated", "success");
    modalUpdateCart.toggle();
  };

  const processTransaction = async (isFormValid) => {
    console.log($serverStatus && $inetStatus ? "IS ONLINE" : "IS OFFLINE");
    if ($serverStatus && $inetStatus) {
      const isValid = await validateCart();
      loadingSubmitPay = false;
      if (!isValid) return;
    }

    tempTransaction = {};
    if (isFormValid && (storedata.id != undefined || storedata._id != undefined)) {
      const itemsOff = Util.createItemsSubmit(cart);

      let now = new Date();
      let orderNoTrans;
      if (lastMinutes == undefined || lastMinutes == null) {
        localStorage.setItem("lastMinutes", format(now, "HHmm"));
        orderNoTrans = mark + format(now, "HHmm");
        lastMinutes = localStorage.getItem("lastMinutes");
      } else if (parseInt(lastMinutes) == parseInt(format(now, "HHmm"))) {
        orderNoTrans = mark + format(addMinutes(now, 1), "HHmm");
        localStorage.setItem("lastMinutes", format(addMinutes(now, 1), "HHmm"));
        lastMinutes = localStorage.getItem("lastMinutes");
      } else {
        orderNoTrans = mark + format(now, "HHmm");
        localStorage.setItem("lastMinutes", format(now, "HHmm"));
        lastMinutes = localStorage.getItem("lastMinutes");
      }

      const data = {
        id: Util.randomString(32),
        code: $barcodeScan
          ? $barcodeScan
          : `${localStorage.getItem("mark")}-${Util.randomString(16)}`,
        orderNo: orderNoTrans,
        storeId: storedata.id || storedata._id,
        store: {
          name: storedata.name,
          id: storedata.id,
          phone: storedata.phone,
        },
        customerId: null,
        employeeId: $connection.userId,
        promos: promoUsed,
        items: itemsOff,
        subtotal: subTotal,
        totalDiscount: parseInt(discTotal),
        campaigns: tempCampaign,
        bonus: bonusProduct,
        paymentType: paymentType,
        tax: taxTotal,
        serviceCharge: serviceCharge,
        rounding: rounding,
        spvDiscount: totalSvpDiscount,
        storeDiscount:
          selectedStoreDiscType == "PERCENT"
            ? storeDiscount
            : storeDiscAmountValueMap,
        remarkStoreDiscount: remarkStoreDiscount,
        totalStoreDiscount: parseInt(totalStoreDiscount),
        roundingType:
          storedata["rounding"] != null ? storedata["rounding"] : "UP",
        paymentDetail: {
          name: paymentDetail.name,
          number: paymentDetail.numberCard,
          cvv: paymentDetail.cvv,
          exp: paymentDetail.expDate,
          paymentValue: Util.getPaymentValueMap(paymentValue),
        },
        total: total,
        customer: customer,
        ojol: ojolPrice,
        fixDiscount: fixDiscountMap,
        fixDiscountRemark,
        status: "SUCCESS",
        createdAt: new Date(),
        createdAtOnLocal: new Date(),
        voucher,
        totalVoucher,
        totalArtistDiscount,
        remarkPinStore,
        storeDiscountType: selectedStoreDiscType,
      };

      const payment = {
        type: paymentType,
        detail: {
          name: paymentDetail.name,
          number: paymentDetail.numberCard,
          cvv: paymentDetail.cvv,
          exp: paymentDetail.expDate,
          paymentValue: Util.getPaymentValueMap(paymentValue),
        },
      };

      tempTransaction = { data };
      tempTransaction = tempTransaction;
      modalLoading.toggle();
			const orders = $tempOr;
			// const orders = JSON.parse(localStorage.getItem('temporders') || '[]');
			if (data.items.length > 0) {
				orders.push({
					data: data,
					customer: customer,
					payment: payment,
					codeScan: $barcodeScan,
					status: "WAITING_FOR_SYNC",
				});
				try {
					tempOr.set(orders);
					barcodeScan.set(null);
					// localStorage.setItem('temporders', JSON.stringify(orders));
					globalThis.pushToast("Successfully Add Transaction.", "success");
					console.log("temptrans", tempTransaction);

					Printy($store, tempTransaction["data"]["items"], tempTransaction["data"], tempTransaction["data"]["promos"]);
					tempIng = [];
					fromScanCode = false;
					modalPay.close();
					modalPrintCO.toggle();
					clearPayment();
				} catch (e) {
					console.log("error-transaction:", e);
					globalThis.pushToast("Transaction Failed! Something Wrong!.", "error");
				}
			} else {
				globalThis.pushToast("Min 1 items Transaction.", "error");
			}
      modalLoading.toggle();
    }
  };

  const clearPayment = () => {
    customer = {
      _id: null,
      name: "",
      phone: null,
      table: "",
    };
    paymentType = "CASH";
    paymentDetail = {
      name: "",
      numberCard: null,
      cvv: null,
      expDate: null,
    };
    storeDiscount = 0;
    remarkStoreDiscount = "";
    totalStoreDiscount = 0;
    storeDiscAmountValue = 0;
    paymentValue = 0;
    promoUsed = [];
    discPercent = 0;
    discValue = 0;
    bonusProduct = [];
    fixDiscount = 0;
    fixDiscountRemark = "";
    codeScan = "";
    enableScan = true;

    cart = [];
    voucher = null;
    totalVoucher = 0;
    totalArtistDiscount = 0;
    pin = "";
    remarkPinStore = "";
    taxTotal = 0;
    isUsedArtistDiscount = false;
  };

  let totalCalc = 0;
  let state = null;
  let calValue = "";
  let tempDataForPrint = {};
  let modalPrint;
  let modalLoading;

  function showModalNote(itemProduct) {
    item = itemProduct;
    modalNote.toggle();
  }

  const _submitBarcode = () => {
    if (codeScan != "") {
      fromScanCode = true;
      enableScan = false;
      modalScan.close();
      modalLoading.toggle();
      cart = [];
      onlineOrderTemp = null;

      const formattedCodeScan = codeScan.toUpperCase();

      $connection
        .call(
          "onlineOrders.findCode",
          formattedCodeScan,
          storedata.id || storedata._id
        )
        .then((res) => {
          barcodeScan.set(formattedCodeScan);
          if (
            res["paymentDetail"]["mid_payment_type"] != null &&
            res["paymentDetail"]["mid_payment_type"] != "" &&
            res["paymentDetail"]["mid_payment_type"] != "cod"
          ) {
            let itemsForTrans = [];
            res["items"].forEach((itemOnline) => {
              const indexProduct = storeProducts.findIndex(
                (data) => data["_id"] == itemOnline["idProduct"]
              );
              if (indexProduct != -1) {
                const productItem = storeProducts[indexProduct];

                let newTop = [];
                productItem.toppings.forEach((top, indexTop) => {
                  let ingFilter = [];
                  if (top.ingredients && top.ingredients.length > 0) {
                    top.ingredients.forEach((ing, indexIng) => {
                      const outOfstock = supplies.filter(
                        (sup) =>
                          sup["itemId"] == ing["_id"] &&
                          ing["measure"] > sup["total"]
                      ).length;
                      if (outOfstock > 0) {
                        ingFilter.push(indexTop);
                      }
                    });
                  }

                  const newTopping = {
                    ...top,
                    available: true,
                  };

                  if (top.status == "ACTIVE") {
                    newTop.push(newTopping);
                  }
                });
                let productInit = {
                  ...productItem,
                  toppings: newTop,
                };

                const itemAdd = {
                  idCart: Math.random(),
                  id: productInit._id,
                  qty: itemOnline["qty"],
                  price: productInit.price,
                  name: productInit.name,
                  images: productInit.images,
                  ingredients: productInit.ingredients,
                  additionals: [],
                  toppings: [],
                  notes: itemOnline["notes"],
                };

                item = itemAdd;
                if (
                  itemOnline["additionals"].filter((s) => s.selected != "")
                    .length > 0
                ) {
                  itemOnline["additionals"]
                    .filter((s) => s.selected != "")
                    .forEach((adds) => {
                      const indexAdds = productInit.additionals.findIndex(
                        (data) => data["_id"] == adds["id"]
                      );
                      if (indexAdds != -1) {
                        const addsItem = productInit.additionals[indexAdds];
                        const indexAddsItem = addsItem.items.findIndex(
                          (s) => s.name == adds["selected"]
                        );
                        if (indexAddsItem != -1) {
                          const itemSelected = addsItem.items[indexAddsItem];
                          handleAddAdditional("additional", {
                            ...itemSelected,
                            parentId: adds["id"],
                            nameParent: adds["name"],
                          });
                        }
                      }
                    });
                }
                if (itemOnline["toppings"].length > 0) {
                  itemOnline["toppings"].forEach((tops) => {
                    const indexTop = productInit["toppings"].findIndex(
                      (top) => top["_id"] == tops["id"]
                    );
                    if (indexTop != -1) {
                      handleAddAdditional(
                        "topping",
                        productInit["toppings"][indexTop]
                      );
                    }
                  });
                }
                itemsForTrans = [...itemsForTrans, item];
                item = null;
              }
            });

            const itemsOff = itemsForTrans.map((item) => {
              return {
                _id: item.id,
                amount: item.qty,
                toppings: item.toppings.map(({ _id, images, name, price }) => ({
                  _id,
                  images,
                  name,
                  price,
                })),
                additionals:
                  item.additionals.length > 0
                    ? [
                        {
                          items: item.additionals.map(
                            ({ name, parentId, nameParent, code, price }) => ({
                              name,
                              parentId,
                              nameParent,
                              code,
                              price,
                            })
                          ),
                        },
                      ]
                    : [],
                name: item.name,
                price: item.price,
                notes: item.notes,
              };
            });

            const custMap = {
              id: res["customer"]["id"],
              name: res["customer"]["name"],
              phone: res["customer"]["phone"]["number"],
              table: res["table"],
            };

            const paymentApp = {
              type: res["paymentDetail"]["mid_payment_type"],
              detail: {
                name: "",
                number: "",
                cvv: "",
                exp: "",
                paymentValue: parseInt(res["paymentDetail"]["mid_trans_value"]),
              },
            };

            let now = new Date();
            let orderNoTrans;
            if (lastMinutes == undefined || lastMinutes == null) {
              localStorage.setItem("lastMinutes", format(now, "HHmm"));
              orderNoTrans = mark + format(now, "HHmm");
              lastMinutes = localStorage.getItem("lastMinutes");
            } else if (parseInt(lastMinutes) == parseInt(format(now, "HHmm"))) {
              orderNoTrans = mark + format(addMinutes(now, 1), "HHmm");
              localStorage.setItem(
                "lastMinutes",
                format(addMinutes(now, 1), "HHmm")
              );
              lastMinutes = localStorage.getItem("lastMinutes");
            } else {
              orderNoTrans = mark + format(now, "HHmm");
              localStorage.setItem("lastMinutes", format(now, "HHmm"));
              lastMinutes = localStorage.getItem("lastMinutes");
            }

            $connection
              .call("transactions.byCode", formattedCodeScan)
              .then((resTrans) => {
                if (resTrans != null) {
                  onlineOrderTemp = resTrans;
                  barcodeScan.set(null);
                  codeScan = "";
                  enableScan = true;
                  if (onlineOrderTemp != null) {
                    modalLoading.toggle();
                    modalOnlineOrder.open();
                  }
                } else {
                  $connection
                    .call(
                      "transactions.sync",
                      custMap,
                      storedata.id || storedata._id,
                      $connection.userId,
                      itemsOff,
                      paymentApp,
                      orderNoTrans,
                      formattedCodeScan,
                      false,
                      0,
                      "",
                      0,
                      res["date"],
                      formattedCodeScan,
                      false,
                      0,
                      "",
                      voucher,
                      totalVoucher
                    )
                    .then((resp) => {
                      barcodeScan.set(null);
                      codeScan = "";
                      enableScan = true;
                      // hideScan();

                      setTimeout(() => {
                        $connection
                          .call("transactions.byCode", resp["code"])
                          .then((ress) => {
                            onlineOrderTemp = ress;
                            modalLoading.toggle();
                            modalOnlineOrder.open();
                          });
                      }, 5000);
                    });
                }
              });
          } else {
            res["items"].forEach((itemOnline) => {
              const indexProduct = storeProducts.findIndex(
                (data) => data["_id"] == itemOnline["idProduct"]
              );
              if (indexProduct != -1) {
                const productItem = storeProducts[indexProduct];

                let productInit = {
                  ...productItem,
                  toppings: Util.mapOnlineOrderToppingForCart(productItem),
                };

                item = Util.createItemFromOnlineOrderForCart(productInit,itemOnline);

                if (
                  itemOnline["additionals"].filter((s) => s.selected != "")
                    .length > 0
                  ) {
                 Util.handleOnlineOrderAdditional(productInit, itemOnline, handleAddAdditional);
                }
                if (itemOnline["toppings"].length > 0) {
                 Util.handleOnlineOrderTopping(productInit, itemOnline, handleAddAdditional);
                }
                titleModal = modalProductOption.Add.title;
                handleAddItem(item, "barcode");
                item = null;
              }
            });
            customer.name = res["customer"]["name"];
            customer.phone = res["customer"]["phone"]["number"];
            customer.table = res["table"] != null ? res["table"] : "";

            voucher = res["voucher"];
            totalVoucher =
              res["totalVoucher"] != undefined && res["totalVoucher"] != null
                ? res["totalVoucher"]
                : 0;
            // hideScan();
            modalLoading.toggle();
          }
        })
        .catch((err) => {
          if (err.error == "code-not-found") {
            globalThis.pushToast(
              "Transaction Code Not Found or Expired.",
              "error"
            );
          } else if (err.error == "transaction-recorded") {
            globalThis.pushToast("Transaction has been recorded.", "error");
          } else if (err.error == "transaction-expired") {
            globalThis.pushToast("Transaction has been expired.", "error");
          } else if (err.error == "transaction-unpaid") {
            globalThis.pushToast(
              "Transaction still pending for payment.",
              "error"
            );
          }
          enableScan = true;
          codeScan = "";
          modalLoading.toggle();
        });
    } else {
      globalThis.pushToast("Transaction Code could not be empty", "error");
    }
  };
  
  const onCloseModalPay = () => {
    customer.name = "";
    customer.phone = null;
    paymentType = "CASH";
    paymentValue = 0;
    paymentDetail = {
      name: "",
      numberCard: null,
      cvv: null,
      expDate: null,
    };
    modalPay.close();
  };

  const onClickRefresh = async () => {
    if ($inetStatus && $serverStatus) {
      // save cart into local storage
      tempCart.set(cart);

      // get all new product & get cart product
      const storeData = await $connection.call("stores.getAllProduct.forPOS", {
        storeId: storedata.id || storedata._id,
        items: Util.createItemsSubmit(cart),
      });

      let storeSelected = $store;
      storeSelected.products = storeData.products;
      store.update(() => storeSelected);

      // restore cart and update cart
      const _restoredCart = tempCart.get();
      storeData.cart.forEach((updCart) => {
        const foundInCart = _restoredCart.find(
          (cartIt) => cartIt.id == updCart.id
        );

        if (foundInCart) {
          const foundProductInActiveMenu = storeData.menus.find(
            (pro) => pro.productId == updCart.id
          );
          if (storeData.hasActiveMenu && !foundProductInActiveMenu) {
            deleteItem(foundInCart);
          }

          // validate product active
          if (updCart.status == "INACTIVE" || !updCart.activeInPos) {
            deleteItem(foundInCart);
          }

          if (updCart.category.status == "INACTIVE") {
            deleteItem(foundInCart);
          }

          // merge data
          updateEditProduct(updCart);
          updateCartEditProduct(updCart);
          updateModalProduct(foundInCart, updCart);
        }
      });

      globalThis.pushToast("Products Updated", "success");
    } else {
      globalThis.pushToast(
        "You must check internet connection or server status.",
        "error"
      );
    }
  };
</script>

<Loading text="Loading..." bind:this={modalLoading} />

<!-- svelte-ignore a11y-media-has-caption -->
<ModalScanBarcode bind:this={modalScan} bind:cart bind:codeScan bind:enableScan on:submitBarcode={_submitBarcode} />

<Modal
  title="Confirm"
  bind:this={modalConfirmLeave}
  index={20}
  confirm
  confirmText="Are you sure want to leave? Inputed data will be removed."
  on:submit={() => {
    fromScanCode = false;
    barcodeScan.set(null);
    modalConfirmLeave.toggle();
    replace("/");
  }}
  on:close={() => {
    modalConfirmLeave.toggle();
  }}
  on:cancel={() => {
    modalConfirmLeave.toggle();
  }}
/>

<Modal
  title="Update Keranjang"
  bind:this={modalUpdateCart}
  index={20}
  confirm
  modalUpdateCart={true}
  hideCloseCorner={true}
  confirmText="Terdapat update terhadap Produk di dalam Keranjang, sehingga perlu adanya Update Keranjang. Apakah Anda ingin Update Keranjang?"
  on:submit={() => {
    onUpdateCartAfterChange();
  }}
  on:cancel={() => {
    clearPayment();
    modalUpdateCart.toggle();
  }}
/>

<Modal
  title="Confirm"
  bind:this={modalPrintCO}
  index={20}
  confirm
  withTitle={false}
  modalPrint
  confirmText="Want Print ?"
  on:printCo={() =>
    PrintyCO(
      $store,
      tempTransaction["data"]["items"],
      tempTransaction["data"],
      tempTransaction["data"]["promos"]
    )}
  on:print={() =>
    Printy(
      $store,
      tempTransaction["data"]["items"],
      tempTransaction["data"],
      tempTransaction["data"]["promos"]
    )}
  on:cancel={() => {
    modalPrintCO.toggle();
  }}
/>

<Modal withTitle={false} index={20} bind:this={modalNote}>
  <div class="w-full flex flex-col">
    <TextArea
      name="itemNote"
      label="Notes"
      form={null}
      maxlength={200}
      bind:value={item["notes"]}
      placeholder=""
    />
    <div class="flex space-x-2 mt-5 w-full">
      <Button
        extClass="flex-1"
        on:click={() => {
          item = null;
          modalNote.toggle();
        }}
        status="gray">Cancel</Button
      >
      <Button
        extClass="flex-1"
        on:click={() => {
          const index = cart.findIndex((c) => c.idCart == item["idCart"]);
          cart[index] = item;
          cart = cart;
          item = null;
          modalNote.toggle();
        }}>Save</Button
      >
    </div>
  </div>
</Modal>

<ModalStoreDiscount
	bind:this={modalStoreDiscount}
	bind:selectedStoreDiscType
	bind:storeDiscAmountValue
	bind:storeDiscount
	bind:totalStoreDiscount
	bind:remarkStoreDiscount
	bind:storeDiscAmountValueMap
	{subTotal}
	{priceNett}
	on:submitStoreDiscount={handlePromoUsed}
/>
<ModalArtistDiscount
	bind:this={modalArtistDiscount}
	bind:isUsedArtistDiscount
	bind:pin
	bind:promos
	bind:remarkPinStore
	bind:remarkStoreDiscount
  {storedata}
	bind:storeDiscAmountValue
	bind:storeDiscount
	bind:subTotal
	bind:totalArtistDiscount
	bind:totalStoreDiscount
	on:submitArtistDiscount={handlePromoUsed}
/>

<Modal bind:this={modalFixDiscount} index={20} withTitle={false}>
  <div class="flex flex-1">
    <MoneyInput
      autofocus
      form={null}
      name="fixDiscount"
      label="Discount"
      bind:value={fixDiscount}
    />
  </div>
  <div class="my-4">
    <TextArea
      name="remarks"
      form={null}
      label="Remarks*"
      maxlength={200}
      bind:value={fixDiscountRemark}
      placeholder=""
    />
  </div>
  <div class="w-full flex space-x-2 justify-end">
    <Button
      on:click={() => {
        fixDiscountRemark = "";
        fixDiscount = 0;
        modalFixDiscount.toggle();
      }}
      status="gray">Cancel</Button
    >
    <Button
      size="large"
      status="success"
      on:click={() => {
        if (fixDiscountRemark.length < 10) {
          globalThis.pushToast("Sorry! Min 10 char Remarks Discount.", "error");
        } else if (fixDiscountMap > tempTotalBeforeRounding) {
          globalThis.pushToast("Sorry! You reach more than Value.", "error");
        } else {
          modalFixDiscount.toggle();
        }
      }}>Ok</Button
    >
  </div>
</Modal>

<ModalPayment
	bind:this={modalPay}
	{codeScan}
	bind:customer
	{fromScanCode}
  bind:paymentDetail
  bind:paymentType
  bind:paymentValue
  {total}
	on:closeModalPayment={() => onCloseModalPay()}
  on:processTransaction={(e)=>processTransaction(e.detail)}
/>

<ModalProduct
	bind:this={modalProduct}
	{errorRequiredModifier}
	bind:item
	{product}
	bind:titleModal
	on:plusItem={() => plusItem(item)}
	on:minusItem={() => minusItem(item, titleModal !== "Add Product" ? "edit" : "")}
	on:addAdditional={(e) => handleAddAdditional(updatedProductActionType.Additional, e.detail)}
	on:addTopping={(e) => handleAddAdditional(updatedProductActionType.Topping, e.detail)}
	on:addItem={() => handleAddItem(item, "pos", product.additionals)}
	on:editItem={() => handleEditItem(item, product.additionals)}
/>

<Modal
  title="Delete Product"
  bind:this={modalConfirm}
  index={20}
  confirm
  on:submit={() => {
    deleteItem(item);
    modalConfirm.toggle();
  }}
  on:close={() => {
    modalConfirm.toggle();
    titleModal = "";
  }}
  on:cancel={() => {
    modalConfirm.toggle();
    titleModal = "";
  }}
/>

<Modal
  title="Confirm"
  bind:this={modalPrint}
  index={30}
  confirm
  withTitle={false}
  modalPrint
  confirmText="Want Print ?"
  on:printCo={() => {
    console.log("for print:", tempDataForPrint);
    if (typeModal == "online") {
      const promos = {
        promos: tempDataForPrint["promos"].map((prom) => {
          return {
            _id: prom._id,
            promoName: prom.name,
            discountType: prom.items[0].discountType,
            discountValue: prom.discount,
            bonus: prom.bonus.map((bon) => {
              return {
                price: bon.price,
                name: bon.name,
                qty: bon.qty,
              };
            }),
          };
        }),
      };
      PrintyCO($store, tempDataForPrint["items"], tempDataForPrint, promos);
    } else {
      PrintyCO(
        $store,
        tempDataForPrint["items"],
        tempDataForPrint,
        tempDataForPrint["promos"]
      );
    }
  }}
  on:print={() => {
    if (typeModal == "online") {
      const promos = {
        promos: tempDataForPrint["promos"].map((prom) => {
          return {
            _id: prom._id,
            promoName: prom.name,
            discountType: prom.items[0].discountType,
            discountValue: prom.discount,
            used: prom.used,
            bonus: prom.bonus.map((bon) => {
              return {
                price: bon.price,
                name: bon.name,
                qty: bon.qty,
              };
            }),
          };
        }),
      };
      Printy($store, tempDataForPrint["items"], tempDataForPrint, promos);
    } else {
      Printy(
        $store,
        tempDataForPrint["items"],
        tempDataForPrint,
        tempDataForPrint["promos"]
      );
    }
  }}
  on:cancel={() => {
    modalPrint.toggle();
  }}
/>

<Modal
  size="large"
  title={typeModal == "online" ? "History Data" : "Sync Data"}
  index={20}
  bind:this={modalOnlineOrders}
  on:close={() => {
    titleModal = "";
    item = null;
    searchOnlineOrders = "";
    dataModalSearch = [];
    modalOnlineOrders.toggle();
  }}
  on:cancel={() => {
    titleModal = "";
    item = null;
    searchOnlineOrders = "";
    dataModalSearch = [];
    modalOnlineOrders.toggle();
  }}
  closed
>
  {#if dataModalSearch.length == 0 && dataModal.length == 0}
    <p class="text-gray-400">Data is empty.</p>
  {:else}
    <div class="flex flex-col">
      <div class="relative flex align-center w-full mb-2">
        <span
          class="z-10 h-full leading-snug font-normal text-center text-gray-400 absolute bg-transparent text-base flex items-center justify-center w-8 pl-3 py-2"
        >
          <i class="bx bx-search" />
        </span>
        <input
          bind:value={searchOnlineOrders}
          on:input={debounce}
          type="text"
          placeholder="Enter Transaction Code or Order No"
          class="appearance-none block pl-10 pr-10 w-full text-dark-second border dark:text-white dark:bg-dark-third rounded-full py-2 px-3 focus:ring-transparent focus:border-gray-800 outline-none"
        />
        <span
          on:click={() => {
            searchOnlineOrders = "";
            debounce();
          }}
          class="z-10 h-full leading-snug font-normal text-center text-gray-400 absolute right-0 bg-transparent text-base flex items-center justify-center w-8 pr-3 py-2 cursor-pointer"
        >
          <i class="bx bx-x" />
        </span>
      </div>
      {#if dataModalTemp.length == 0}
				<p class="text-gray-400">{typeModal == "online" ? "No History Found." : "Transaction not found"}</p>
			{:else}
				<div>
					{#each dataModalTemp as data}
						<TransactionCard
							{data}
							on:onprint={() => {
								tempDataForPrint = {};
								tempDataForPrint = data;
								tempDataForPrint = tempDataForPrint;
								modalPrint.toggle();
							}}
						/>
					{/each}
				</div>
			{/if}
    </div>
  {/if}
</Modal>

<ModalOnlineOrder bind:this={modalOnlineOrder} {onlineOrderTemp}/>

<Modal
  title="Confirmation"
  bind:this={modalConfirmToggle}
  index={20}
  confirm
  confirmText="Switching the Price Mode will remove your products in the cart. Are you sure you want to continue?"
  on:submit={() => {
    ojolPrice = !ojolPrice;
    cart = [];
    promoUsed = [];
    fixDiscount = 0;
    fixDiscountRemark = "";
    storeDiscount = 0;
    totalStoreDiscount = 0;
    remarkStoreDiscount = "";
    storeDiscAmountValue = 0;
    modalConfirmToggle.toggle();
  }}
  on:close={() => {
    ojolPrice = ojolPrice;
    modalConfirmToggle.toggle();
  }}
  on:cancel={() => {
    ojolPrice = ojolPrice;
    modalConfirmToggle.toggle();
  }}
/>

<Modal
  title="Confirmation"
  bind:this={modalConfirmBarcode}
  index={20}
  confirm
  confirmText="Barcode Scan will remove your products in the Cart. Are you sure you want to continue?"
  on:submit={() => {
    ojolPrice = false;
    cart = [];
    modalConfirmBarcode.toggle();
    modalScan.open();
  }}
  on:close={() => {
    modalConfirmBarcode.toggle();
  }}
  on:cancel={() => {
    modalConfirmBarcode.toggle();
  }}
/>

<div class="relative p-4 bg-gray-50">
  <div class="flex flex-wrap w-full">
    <div
      id="scrollbarSide"
      class="w-7/12 md:8/12 relative bg-gray-50 h-screen {dataFiltered.length >
      6
        ? 'overflow-y-scroll'
        : ''} overflow-x-hidden max-h-screen"
    >
      <div
        class="flex flex-col fixed top-0 left-0 w-7/12 items-center z-10 bg-gray-50 p-4 pb-0"
      >
        <div class="flex w-full items-center justify-between mb-4">
          <div class="flex">
            <div
              class="flex items-center cursor-pointer"
              on:click={() => modalConfirmLeave.toggle()}
            >
              <i class="bx bxs-chevron-left text-3xl" />
              <h5 class="ml-2 text-md font-medium text-dark-second mr-5">
                POS
              </h5>
            </div>

            <!-- <div
              class="{$inetStatus
                ? 'bg-green-500'
                : 'bg-red-500'} p-2 text-white text-sm mr-2"
            >
              Internet Status: {$inetStatus ? "Online" : "Offline"}
            </div>

            <div
              class="{$inetStatus && $serverStatus
                ? 'bg-green-500'
                : 'bg-red-500'} p-2 text-white text-sm"
            >
              Server Status: {$inetStatus && $serverStatus
                ? "Online"
                : "Offline"}
            </div> -->
          </div>

          <div class="flex">
            <div
              class="relative mr-2 flex items-center justify-center bg-primary text-white px-4 py-1 rounded-md cursor-pointer {$inetStatus &&
              $serverStatus
                ? 'cursor-pointer'
                : 'opacity-50 cursor-not-allowed'}"
              on:click={() => {
                onClickRefresh();
              }}
            >
              <i class="bx bx-refresh text-xl mr-1" />
              <span>Refresh</span>
            </div>
            <div
              class="relative mr-2 flex items-center justify-center bg-primary text-white px-4 py-1 rounded-md cursor-pointer"
              on:click={() => {
                if ($inetStatus && $serverStatus) {
                  let idStore =
                    $store && $store.id != undefined
                      ? $store.id
                      : $store && $store._id != undefined
                        ? $store._id
                        : null;
                  typeModal = "online";
                  $connection
                    .call("transactions.get.history.limit.forPos", idStore)
                    .then((items) => {
                      dataModal = items;
                      dataModalTemp = dataModal.sort((a, b) => {
                        // sort tranasaction date in descending order
                        const dateA = new Date(a.createdAtOnLocal);
                        const dateB = new Date(b.createdAtOnLocal);
                        if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
                          return 0; // Handle invalid dates
                        }
                        return dateB.getTime() - dateA.getTime(); // Perform the comparison
                      });
                    })
                    .catch((err) => {
                      globalThis.pushToast(err.reason, "error");
                    });
                  modalOnlineOrders.toggle();
                } else {
                  globalThis.pushToast(
                    "You must check internet connection or server status.",
                    "error"
                  );
                }
              }}
            >
              <i class="bx bx-history text-lg cursor-pointer mr-2" />

              <span>History</span>
            </div>
            <div
              class="relative flex items-center justify-center bg-primary text-white px-4 py-1 rounded-md cursor-pointer"
              on:click={() => {
                typeModal = "offline";
                dataModal = $tempOr
                  .filter((ord) => ord && ord["data"]["items"].length > 0)
                  .map((e) => e["data"]);
                dataModalTemp = dataModal.reverse();
                modalOnlineOrders.toggle();
              }}
            >
              <i class="bx bx-data text-lg cursor-pointer mr-2" />

              <span>Sync</span>

              <div
                class="absolute top-0 -right-2 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-yellow-400 rounded-full"
              >
                {$tempOr.filter((ord) => ord && ord["data"]["items"].length > 0)
                  .length}
              </div>
            </div>
          </div>
        </div>

        <div class="flex w-full items-center">
          <div class="relative flex align-center w-full">
            <span
              class="z-10 h-full leading-snug font-normal text-center text-gray-400 absolute bg-transparent text-base flex items-center justify-center w-8 pl-3 py-2"
            >
              <i class="bx bx-search" />
            </span>
            <input
              bind:value={searchKeyword}
              on:input={debounce}
              type="text"
              placeholder="Enter Product Name or SKU"
              class="appearance-none block pl-10 pr-10 w-full text-dark-second border dark:text-white dark:bg-dark-third rounded-full py-2 px-3 focus:ring-transparent focus:border-gray-800 outline-none"
            />
            <span
              on:click={() => {
                searchKeyword = "";
                debounce();
              }}
              class="z-10 h-full leading-snug font-normal text-center text-gray-400 absolute right-0 bg-transparent text-base flex items-center justify-center w-8 pr-3 py-2 cursor-pointer"
            >
              <i class="bx bx-x" />
            </span>
          </div>
          <i
            class="bx bx-scan text-3xl cursor-pointer ml-3 mr-3"
            on:click={() => showScan()}
          />
        </div>
        <div class="w-full pr-2 py-3 flex justify-end">
          <Switch
            bind:value={ojolPrice}
            checked={ojolPrice}
            name="ojol_price"
            textRight={"OJOL PRICE"}
            on:change={(e) => {
              if (cart.length > 0) {
                modalConfirmToggle.toggle();
              } else {
                ojolPrice = !ojolPrice;
              }
            }}
          />
        </div>
      </div>
      <div class="flex flex-col pr-6 relative z-0 pt-36 bg-gray-50">
        {#if dataFiltered.length === 0}
          <h4>Product Not Found.</h4>
        {/if}
        {#if allMenuInActive}
          <div class="grid grid-cols-3 gap-3">
            {#each dataFiltered as item}
              <ProductCard
                {cart}
                {item}
                {ojolPrice}
                on:onclick={() => {
                  if (item["availability"] == "AVAILABLE") {
                    showModal(item, modalProductOption.Add.title);
                  } else {
                    globalThis.pushToast(
                      "Sorry! This Product out of stock.",
                      "error"
                    );
                  }
                }}
              />
            {/each}
          </div>
        {:else}
          {#each dataFiltered as menu}
            <p class="text-sm font-medium">{menu.name}</p>
            <div class="grid grid-cols-3 gap-3">
              {#each menu.products as item}
                <ProductCard
                  {cart}
                  {item}
                  {ojolPrice}
                  on:onclick={() => {
                    if (item["availability"] == "AVAILABLE") {
                      showModal(item, modalProductOption.Add.title);
                    } else {
                      globalThis.pushToast(
                        "Sorry! This Product out of stock.",
                        "error"
                      );
                    }
                  }}
                />
              {/each}
            </div>
          {/each}
        {/if}
      </div>
    </div>
    <div
      class="w-5/12 md:4/12 bg-white fixed right-0 top-0 bottom-0 border-l border-gray-300 flex flex-col"
    >
      <div
        class="px-7 py-2 2xl:py-4 border-b border-gray-300 w-100 grid items-center flex-none"
      >
        <div class="flex justify-between items-center">
          <div class="flex flex-col items-start">
            <h4 class="text-sm 2xl:text-md text-gray-600 font-medium">
              Cart ({cart.length})
            </h4>
            <!-- {#if $objOfflineStatus.status == "offline"}
							<div
								class="text-center rounded-lg px-4 py-1 bg-red-500 text-white text-xs"
							>
								Offline Mode!
							</div>
						{/if} -->
          </div>
          <div class="flex flex-col items-end">
            <p class="text-sm 2xl:text-md font-medium text-gray-600">
              Served by {$user.profile?.name}
            </p>
            <p class="text-sm text-gray-600 text-right">
              {Util.formatDate(new Date(), "long")}
              {timeStr}
            </p>
          </div>
        </div>
        <!-- <div class="flex justify-end items-center mt-2">
					<h4 class="text-sm text-white bg-primary px-2 py-1 rounded-md">
						Transaction #001
					</h4> 
					<p class="text-sm text-gray-800 text-right">
						{Util.formatDate(new Date(), 'long')}
						{timeStr}
					</p>
				</div> -->
      </div>
      <div class="flex-auto flex flex-col justify-between overflow-y-auto">
        <div
          id="scrollbar"
          class="p-6 overflow-y-auto overflow-auto flex flex-col space-y-2"
        >
          {#if !loadingSubmitPay}
            {#if cart.length === 0}
              <h4 class="text-gray-500">Cart Empty</h4>
            {/if}
            {#each cart as item}
              <CartCard
                {item}
                on:edit={() => {
                  showModal(item, modalProductOption.Edit.title);
                }}
                on:notes={() => {
                  showModalNote(item);
                }}
                on:minus={() => minusItem(item)}
                on:plus={() => plusItem(item)}
              />
            {/each}
          {/if}
        </div>
        <div class="w-full bottom-0 border-t border-gray-300">
          <div class="px-6 py-4">
            <div class="flex justify-between items-center">
              <h4 class="text-md font-medium text-gray-800">Sub Total</h4>
              <div class="flex items-center">
                <p
                  class="text-md font-medium {cart.length > 0 && subTotal == 0
                    ? 'text-red-500'
                    : 'text-gray-800'} "
                >
                  {Util.formatRupiah(subTotal, "Rp. ")}
                </p>
              </div>
            </div>
            <div class="flex justify-between items-center mt-1">
              <div class="flex items-center">
                <h4 class="text-md font-medium text-gray-800 mr-3">
                  Promo Discount
                </h4>
              </div>
              <div class="flex items-center">
                <p
                  class="text-md font-medium text-red-500 flex items-center space-x-2 relative"
                >
                  {#if discTotal > 0}
                    <i
                      class="bx bxs-info-circle text-gray-900 mr-1"
                      on:mouseenter={() => (tooltipPromoDiscount = true)}
                      on:mouseleave={() => (tooltipPromoDiscount = false)}
                    />
                    <div
                      class="tooltip left {tooltipPromoDiscount
                        ? 'visible'
                        : 'invisible'}"
                      style="z-index: 99;"
                    >
                      <p class="mb-1">Promo Discount</p>
                      {#each tooltipDiscountMap as item}
                        <p>
                          {item["qty"]}
                          {item["name"]} ({Util.formatRupiah(
                            Math.round(item["value"]),
                            "Rp. "
                          )})
                        </p>
                      {/each}
                    </div>
                  {/if}
                  - {Util.formatRupiah(discTotal, "Rp. ")}
                </p>
              </div>
            </div>
            <div class="flex justify-between items-center mt-1">
              <div class="flex items-center">
                <h4 class="text-md font-medium text-gray-800 mr-3">
                  Store Discount {#if cart.length > 0 && totalArtistDiscount == 0}<Button
                      size="small"
                      on:click={() => {
                        if (
                          (selectedStoreDiscType == "PERCENT" &&
                            storeDiscount == 0) ||
                          (selectedStoreDiscType == "AMOUNT" &&
                            storeDiscAmountValueMap == 0)
                        ) {
                          selectedStoreDiscType = "PERCENT";
                          modalStoreDiscount.open();
                        } else {
                          storeDiscount = 0;
                          remarkStoreDiscount = "";
                          totalStoreDiscount = 0;
                          storeDiscAmountValue = 0;
                          handlePromoUsed();
                        }
                      }}
                    >
                      {#if storeDiscount > 0 || storeDiscAmountValueMap > 0}
                        <i class="bx bx-trash" />
                      {:else}
                        <i class="bx bx-edit" />
                      {/if}
                    </Button>
                  {/if}
                </h4>
              </div>
              <div class="flex items-center">
                <p
                  class="text-md font-medium text-red-500 flex items-center space-x-2 relative"
                >
                  {#if cart.length > 0 && tooltipDiscountStoreMap.length > 0 && ((selectedStoreDiscType == "PERCENT" && storeDiscount > 0) || (selectedStoreDiscType == "AMOUNT" && storeDiscAmountValueMap > 0))}
                    <i
                      class="bx bxs-info-circle text-gray-900 mr-1"
                      on:mouseenter={() => (tooltipStoreDiscount = true)}
                      on:mouseleave={() => (tooltipStoreDiscount = false)}
                    />
                    <div
                      class="tooltip left {tooltipStoreDiscount
                        ? 'visible'
                        : 'invisible'}"
                      style="right: 100%;"
                    >
                      <p class="mb-1">Store Discount</p>
                      {#each tooltipDiscountStoreMap as item}
                        {#if selectedStoreDiscType == "PERCENT"}
                          <p>
                            {item["qty"]}
                            {item["name"]} ({Util.formatRupiah(
                              Math.round(item["value"]),
                              "Rp. "
                            )})
                          </p>
                        {:else}
                          <p>
                            {item["qty"]}
                            {item["name"]}
                          </p>
                        {/if}
                      {/each}
                    </div>
                  {/if}
                  - {storeDiscount > 0 || storeDiscAmountValueMap > 0
                    ? Util.formatRupiah(parseInt(totalStoreDiscount), "Rp. ")
                    : "Rp. 0"}
                </p>
              </div>
            </div>
            {#if totalVoucher > 0}
              <div class="flex justify-between items-center mt-1">
                <div class="flex items-center">
                  <h4 class="text-md font-medium text-gray-800 mr-3">
                    Potongan Voucher
                  </h4>
                </div>
                <div class="flex items-center">
                  <p
                    class="text-md font-medium text-red-500 flex items-center space-x-2 relative"
                  >
                    {#if totalVoucher > 0}
                      <i
                        class="bx bxs-info-circle text-gray-900 mr-1"
                        on:mouseenter={() => (tooltipVoucher = true)}
                        on:mouseleave={() => (tooltipVoucher = false)}
                      />
                      <div
                        class="tooltip left right {tooltipVoucher
                          ? 'visible'
                          : 'invisible'}"
                      >
                        {#each voucher as item}
                          <p>
                            {item["name"]} ({Util.formatRupiah(
                              Math.round(item["valueVoucher"]),
                              "Rp. "
                            )})
                          </p>
                        {/each}
                      </div>
                    {/if}
                    - {totalVoucher > 0
                      ? Util.formatRupiah(totalVoucher, "Rp. ")
                      : "Rp. 0"}
                  </p>
                </div>
              </div>
            {/if}
            <div class="flex justify-between items-center mt-1">
              <div class="flex items-center">
                <h4 class="text-md font-medium text-gray-800 mr-3">
                  Artist Discount {#if cart.length > 0}<Button
                      size="small"
                      on:click={() => {
                        if (totalArtistDiscount == 0) {
                          modalArtistDiscount.open();
                        } else {
                          pin = "";
                          remarkPinStore = "";
                          totalArtistDiscount = 0;
                          isUsedArtistDiscount = false;

                          handlePromoUsed();
                        }
                      }}
                    >
                      {#if totalArtistDiscount > 0}
                        <i class="bx bx-trash" />
                      {:else}
                        <i class="bx bx-edit" />
                      {/if}
                    </Button>
                  {/if}
                </h4>
              </div>
              <div class="flex items-center">
                <p class="text-md font-medium text-red-500">
                  - {Util.formatRupiah(totalArtistDiscount, "Rp. ")}
                </p>
              </div>
            </div>
            <div class="flex justify-between items-center mt-1">
              <div class="flex items-center">
                <h4 class="text-md font-medium text-gray-800 mr-3">
                  Service Charge
                </h4>
              </div>
              <div class="flex items-center">
                <p class="text-md font-medium text-gray-800">
                  {Util.formatRupiah(serviceCharge.toFixed(0), "Rp. ")}
                </p>
              </div>
            </div>
            <div class="flex justify-between items-center mt-1">
              <div class="flex items-center">
                <h4 class="text-md font-medium text-gray-800 mr-3">Tax</h4>
              </div>
              <div class="flex items-center">
                <p class="text-md font-medium text-gray-800">
                  {Util.formatRupiah(taxTotal.toFixed(0), "Rp. ")}
                </p>
              </div>
            </div>
            <!-- <div class="flex justify-between items-center mt-1">
							<div class="flex items-center">
								<h4 class="text-md font-medium text-gray-800 mr-3">
									Discount {#if cart.length > 0}<Button
											size="small"
											on:click="{() => {
												if (fixDiscountMap == 0) {
													modalFixDiscount.toggle();
												} else {
													fixDiscount = 0;
													fixDiscountRemark = '';
												}
											}}"
										>
											{#if fixDiscountMap > 0}
												<i class="bx bx-trash"></i>
											{:else}
												<i class="bx bx-edit"></i>
											{/if}
										</Button>{/if}
								</h4>
							</div>
							<div class="flex items-center">
								<p class="text-md font-medium  text-red-500">
									- {Util.formatRupiah(
										cart.length == 0 ? 0 : fixDiscountMap.toFixed(0),
										"Rp. ",
									)}
								</p>
							</div>
						</div> -->
            <div class="flex justify-between items-center mt-1">
              <div class="flex items-center">
                <h4 class="text-md font-medium text-gray-800 mr-3">Rounding</h4>
              </div>
              <div class="flex items-center">
                <p
                  class="text-md font-medium {$store['rounding'] != null &&
                  $store['rounding'] == 'DOWN'
                    ? 'text-red-500'
                    : 'text-gray-800'} "
                >
                  {$store["rounding"] != null && $store["rounding"] == "DOWN"
                    ? "-"
                    : ""}
                  {Util.formatRupiah(rounding, "Rp. ")}
                </p>
              </div>
            </div>
            <div class="flex justify-between items-center mt-1">
              <h4 class="text-xl font-semibold text-gray-800">Total</h4>
              <div class="flex items-center">
                <p
                  class="text-xl font-bold {total <= 0
                    ? 'text-red-500'
                    : 'text-gray-800'} "
                >
                  {Util.formatRupiah(total, "Rp. ")}
                </p>
              </div>
            </div>
          </div>
          <div class="flex items-center space-x-2 pb-4 px-4">
            <div
              on:click={() => modalConfirmLeave.toggle()}
              class="flex-1 bg-gray-400 text-white py-4 rounded-md flex justify-center items-center cursor-pointer"
            >
              <h4 class="font-semibold text-lg">Cancel</h4>
            </div>
            <div
              on:click={() => {
                if (cart.length > 0) {
                  if (total < 0) {
                    globalThis.pushToast(
                      "Total transaction should not be minus.",
                      "error"
                    );
                  } else {
                    modalPay.open();
                  }
                } else {
                  globalThis.pushToast("Cart Empty.", "error");
                }
              }}
              class="flex-1 bg-primary text-white py-4 rounded-md flex justify-center items-center cursor-pointer"
            >
              <h4 class="font-semibold text-lg">Pay Now</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  #scrollbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  #scrollbar::-webkit-scrollbar {
    width: 10px;
    -webkit-overflow-scrolling: touch;
    background-color: #f5f5f5;
  }

  #scrollbar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #9c6039;
  }
  .tooltip {
    position: absolute;

    /* move to right */
    left: -200%; /* and add a small left margin */

    /* basic styles */
    background: #333;
    color: white;
    font-weight: bold;
    padding: 4px 8px;
    font-size: 13px;
    border-radius: 4px;

    opacity: 1;
    transition: 0.3s opacity;
  }

  .tooltip:hover {
    opacity: 1;
  }

  .tooltip::after {
    opacity: 0;
    transition: 0.3s;
  }

  .tooltip.left::before {
    /* reset defaults */
    left: initial;
    margin: initial;

    /* set new values */
    right: 100%;
    margin-right: 15px;
  }
</style>
