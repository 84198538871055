<script>
	import { createEventDispatcher } from "svelte";
	import { imageUrlValid } from "../../Utils/helper";
	import ToppingCard from "../card/ToppingCard.svelte";
	import Button from "../control/Button.svelte";
	import Modal from "../Modal.svelte";
	import Util from "../Util/Utility";

	const dispatch = createEventDispatcher();
	export let errorRequiredModifier;
	export let item;
	export let product;
	export let titleModal;

	let modalProduct;

	export function open() {
		modalProduct.toggle();
	}

	export function close() {
		modalProduct.close();
	}

	const resolveModifier = (_modifier = []) => {
		const newModif = [];
		_modifier
			.filter((adds) => adds?.status == "ACTIVE")
			.forEach((adds) => {
				const newItems = adds.items.filter((item) => !!item?.active && !!item?.activeInPos);
				if (newItems.length > 0) {
					newModif.push({ ...adds, items: newItems });
				}
			});

		return newModif;
	};
</script>

<Modal
	size="large"
	title={titleModal}
	index={20}
	bind:this={modalProduct}
	on:close={() => {
		titleModal = "";
		item = null;
		modalProduct.close();
	}}
	on:cancel={() => {
		titleModal = "";
		item = null;
		modalProduct.close();
	}}
	closed
>
	{#if item != null}
		<div class="flex">
			<div class="flex flex-col w-1/2">
				<div class="flex items-center mb-3">
					<img src={imageUrlValid(item["images"][0].url)} alt=".." class="w-20 h-20 object-cover rounded-md" />
					<div class="ml-3">
						<h4 class="text-sm 2xl:text-xl font-medium text-primary break-words-self">
							{item.name}
						</h4>
						<div class="flex items-center">
							<p class="text-sm 2xl:text-md text-gray-800 font-medium">
								{Util.formatRupiah(item.price, "Rp. ")}
							</p>
						</div>
						<div class="flex items-center mt-1">
							<div class="flex flex-wrap w-full relative h-7 bg-gray-100 items-center rounded">
								<div class="flex -mr-px justify-center w-15 pl-2">
									<span
										on:click={() => dispatch("minusItem")}
										class="flex items-center leading-normal bg-gray-100 rounded rounded-r-none text-xl whitespace-no-wrap text-gray-600 cursor-pointer"
									>
										<i class="bx bx-minus" />
									</span>
								</div>
								<input
									bind:value={item.qty}
									type="text"
									maxlength="4"
									class="flex-shrink flex-grow leading-normal w-12 text-center flex-1 border-0 h-7 relative self-center text-md outline-none"
									placeholder="1"
									disabled
								/>
								<div class="flex -mr-px justify-center w-15 pr-2">
									<span
										on:click={() => dispatch("plusItem")}
										class="flex items-center leading-normal bg-gray-100 rounded rounded-r-none text-xl whitespace-no-wrap text-gray-600 cursor-pointer"
									>
										<i class="bx bx-plus" />
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="">
					{#if resolveModifier(product.additionals).length > 0}
						{#each resolveModifier(product.additionals) as additional}
							<div class="flex flex-col flex-wrap">
								<h4 class="text-md text-gray-800 mb-2">
									{additional["name"]}
									<span class="text-md text-red-500 font-bold italic">{additional.isRequired ? "*" : ""}</span>
									<span
										class="text-md {errorRequiredModifier && additional.isRequired
											? 'text-red-500'
											: 'text-gray-800'} font-bold italic"
									>
										{additional.isRequired ? " (Pilih 1)" : " (Opsional)"}
									</span>
								</h4>
								<div class="flex flex-wrap space-x-1 items-center justify-start">
									{#each additional["items"].filter((add) => add["active"] == true) as itemAdd}
										<div
											class="py-1 px-4 mb-2 flex justify-center items-center rounded-full border-2 border-primary {item.additionals.filter(
												(item) => item.code == itemAdd['code']
											).length > 0
												? 'bg-primary text-white'
												: 'text-dark-second bg-white'} text-sm cursor-pointer hover:opacity-90"
											on:click={() =>
												dispatch("addAdditional", {
													...itemAdd,
													parentId: additional["_id"],
													nameParent: additional["name"],
												})}
										>
											<p class="break-words-self">
												{itemAdd["name"]}
											</p>
											<p class="font-medium whitespace-nowrap"> + {Util.formatRupiah(itemAdd["price"], "Rp. ")}</p>
										</div>
									{/each}
								</div>
							</div>
						{/each}
					{:else}
						<p class="text-gray-400 text-sm">Modifier not available.</p>
					{/if}
				</div>
			</div>
			<div class="px-4 border-l flex flex-col w-1/2 border-gray-100 ml-4 relative">
				<h4 class="text-md mb-3 font-medium text-gray-800">Topping</h4>

				{#if product.toppings.length > 0}
					<div
						id="scrollbarTopping"
						class="grid scroll-x--rows-2 gap-2 {product.toppings.length > 4 ? 'overflow-x-scroll' : 'overflow-x-hidden'} pb-5"
					>
						{#each product.toppings as topping}
							<ToppingCard
								description={Util.formatRupiah(topping["price"], "Rp. ")}
								name={topping["name"]}
								out={!topping["available"]}
								image={imageUrlValid(topping["images"][0].url)}
								on:card-click={() =>
									topping["available"]
										? dispatch("addTopping", topping)
										: globalThis.pushToast("Sorry! Not Enough Stock.", "error")}
								selectable
								selected={item.toppings.filter((item) => item._id == topping["_id"]).length > 0}
							/>
						{/each}
					</div>
				{:else}
					<p class="text-gray-400 text-sm">Topping not available.</p>
				{/if}
			</div>
		</div>
		<div class="relative h-14">
			{#if titleModal === "Add Produk"}
				<div class="absolute bottom-0 right-0 flex">
					<Button
						status="gray"
						size="large"
						on:click={() => {
							modalProduct.close();
							titleModal = "";
						}}>Cancel</Button
					>
					<Button extClass="ml-2" size="large" status="success" on:click={() => dispatch("addItem")}>Add To Cart</Button>
				</div>
			{:else}
				<div class="absolute bottom-0 right-0 flex">
					<Button
						extClass="ml-2"
						size="large"
						status="success"
						on:click={() => {
							dispatch("editItem");
						}}>Ok</Button
					>
				</div>
			{/if}
		</div>
	{/if}
</Modal>


<style>
	/* Styling for Topping scrollbar, if topping component needs to be moved, this code needs to be moved as well */
	.grid.scroll-x--rows-2 {
		display: grid;
		/* auto auto is telling the browser to render two rows, if needed */
		grid-template-rows: auto auto;
		grid-auto-flow: column;
		overflow-x: scroll;
	}
	#scrollbarTopping::-webkit-scrollbar-track {
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		border-radius: 10px;
		background-color: #f5f5f5;
	}

	#scrollbarTopping::-webkit-scrollbar {
		width: 10px;
		-webkit-overflow-scrolling: touch;
		background-color: #f5f5f5;
	}

	#scrollbarTopping::-webkit-scrollbar-thumb {
		border-radius: 10px;
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		background-color: #9c6039;
	}
</style>
