import { format, subMonths } from "date-fns";
import { modalProductOption } from "../../enums/pos";
import { updatedProductActionType } from "../../enums/action";
const now = new Date();
const year = now.getFullYear();
const month = now.getMonth();
const day = now.getDate();
let validBlockMonth = "";
//01 at the end because we want to block 1 month from current month, not 1 month from today
validBlockMonth = format(subMonths(new Date(year, month, day), 1), "yyyy-MM-01");
export const blockedDate = validBlockMonth;
export default {
    cleanHtmlTags(str) {
        if (str === null || str === "")
            return false;
        else
            str = str.toString();
        // Regular expression to identify HTML tags in
        // the input string. Replacing the identified
        // HTML tag with a null string.
        return str.replace(/(<([^>]+)>)/gi, "");
    },
    validateDateForm(dates) {
        let res = true;
        if (dates.from.getMonth() < 7) {
            res = false;
        }
        return res;
    },
    randomString(length) {
        var chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
        var result = "";
        for (var i = length; i > 0; --i)
            result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    },
    validateInput(object, key, param) {
        if (object[key]) {
            const err = object[key].errors.includes(param);
            return err;
        }
        else {
            return false;
        }
    },
    validateErrorField(object, key) {
        if (object[key]) {
            if (object[key].errors.length > 0) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    },
    formatDate(object, type) {
        if (type == "short") {
            return format(object, "dd/MM/yyyy");
        }
        else if (type == "detail") {
            return format(object, "dd/MM/yyyy HH:mm");
        }
        else if (type == "long-detail") {
            return format(object, "dd MMMM yyyy HH:mm");
        }
        else {
            return format(object, "eeee, dd/MM/yyyy");
        }
    },
    formatRupiah(angka, prefix) {
        var number_string;
        let isMinus = false;
        if (angka.toString()[0] == "-") {
            number_string = angka.toString().substr(1, angka.length);
            isMinus = true;
        }
        else {
            number_string = angka.toString();
        }
        var sisa = number_string.length % 3, rupiah = number_string.substr(0, sisa), ribuan = number_string.substr(sisa).match(/\d{3}/g);
        if (ribuan) {
            var separator = sisa ? "." : "";
            rupiah += separator + ribuan.join(".");
        }
        return prefix == undefined
            ? rupiah
            : rupiah
                ? isMinus
                    ? "- Rp. " + rupiah
                    : "Rp. " + rupiah
                : "";
    },
    formatNumber(angka) {
        if (!Number.isInteger(angka)) {
            return parseInt(angka.replace(/[^,\d]/g, ""));
        }
        return angka;
    },
    getDisplayDate(date) {
        var today = new Date();
        var compDate = date;
        var diff = today.getTime() - compDate.getTime();
        if (compDate.getTime() == today.getTime()) {
            return "Today";
        }
        else if (diff <= 24 * 60 * 60 * 1000) {
            return "Yesterday";
        }
        else {
            return compDate.toDateString();
        }
    },
    roundingUp(value) {
        var s = parseFloat("0." + (value / 100 + "").split(".")[1]);
        var val = s > 0 ? (1 - s) * 100 : 0;
        return Math.round(val);
    },
    roundingDown(value) {
        let val = 0;
        if (Number.isInteger(value)) {
            const valueStr = value.toString();
            const last = valueStr.substring(valueStr.length - 1);
            val =
                (value / 100 + "").split(".")[1] == undefined
                    ? 0
                    : parseInt((value / 100 + "").split(".")[1]);
            let res = val;
            if (last == "0") {
                res = parseInt(val + last);
            }
            return res;
        }
        else {
            val = parseFloat("0." + (value / 100 + "").split(".")[1]) * 100;
            return Math.floor(val);
        }
    },
    accumulateProductPrices(products, isQuantified, promoQty) {
        if (products && products[0]) {
            let _products = [];
            if (isQuantified) {
                _products = [...products];
            }
            let total = 0;
            _products.forEach((item) => {
                total +=
                    promoQty *
                        getProductBundlePrice(item, item.toppings, item.additionals);
            });
            // console.log("tot", total);
            return _products.reduce((sum, item) => sum +
                promoQty *
                    getProductBundlePrice(item, item.toppings, item.additionals), 0);
        }
        return 0;
    },
    /**
    * This function will return two properties : promos and stores.
    * The former will process cart item's data that exists in a promo by adding promo and price information
    * and map the item's additional and topping.
    * The latter will also do the same, but for items that do not exist in promo and it won't add
    * promo information.
    *
    */
    handlePromoUsedNew(allPromos, allItems, storeDiscount, isStoreDiscPercent, isStoreDisc100) {
        let arrTemp = allItems.map((m) => m);
        let arrPromoDiscount = [];
        let arrStoreDiscount = [];
        if (!!isStoreDisc100) {
            flushToStoreDiscount();
            return {
                promos: arrPromoDiscount,
                stores: arrStoreDiscount,
            };
        }
        // console.log(arrTemp);
        allPromos.forEach((promo) => {
            promo.items.forEach(({ products, discountType, value, additionalValue, bonus }) => {
                while (isPromoItemProductsExist(products, arrTemp)) {
                    let temp = getProducstByPromoItemProducts(products, arrTemp);
                    if (temp.length > 0) {
                        let subtotal = calculateSubtotal(temp);
                        arrPromoDiscount.push({
                            discountType,
                            discount: value,
                            promoName: promo.name,
                            discountValue: Math.floor(subtotal -
                                calculatePriceAfterDiscount(discountType, subtotal, value)),
                            additionalDiscount: additionalValue,
                            bonus,
                            products: temp,
                            subtotal,
                            priceAfterDiscount: calculatePriceAfterDiscount(discountType, subtotal, value),
                        });
                        // pop the product that is already mapped
                        const indexesToPop = temp.map((it) => it.index);
                        arrTemp = arrTemp.filter((it, idx) => !indexesToPop.includes(idx));
                    }
                }
            });
        });
        flushToStoreDiscount();
        function flushToStoreDiscount() {
            arrTemp.forEach((it) => {
                it.discount = storeDiscount;
                it.subtotal = calculateSubtotal([it]);
                it.priceAfterDiscount = isStoreDiscPercent ? (it.subtotal * (100 - storeDiscount)) / 100 : (it.subtotal - (storeDiscount / arrTemp.length));
                it.valueDiscount = it.subtotal - it.priceAfterDiscount;
                it.additionals = it.additionals.map(({ name, price, code, parentId, nameParent }) => ({
                    name,
                    price,
                    code,
                    parentId,
                    nameParent,
                }));
                it.toppings = it.toppings.map(({ _id, images, name, price }) => ({
                    _id,
                    images,
                    name,
                    ingredients: [],
                    price,
                }));
                arrStoreDiscount.push(it);
            });
            arrTemp = [];
        }
        return {
            promos: arrPromoDiscount,
            stores: arrStoreDiscount.map(({ idCart, id, qty, price, name, images, additionals, toppings, notes, allQty, discount, subtotal, priceAfterDiscount, valueDiscount, }) => {
                return {
                    idCart,
                    id,
                    qty,
                    price,
                    name,
                    images,
                    ingredients: [],
                    additionals,
                    toppings,
                    notes,
                    allQty,
                    discount,
                    subtotal,
                    priceAfterDiscount,
                    valueDiscount,
                };
            }),
        };
    },
    sortPaymentTypeByCreatedAt,
    mapOnlineOrderToppingForCart(product) {
        let newTop = [];
        product.toppings.forEach((top) => {
            const newTopping = Object.assign(Object.assign({}, top), { available: true });
            if (top.status == "ACTIVE") {
                newTop.push(newTopping);
            }
        });
        return newTop;
    },
    createItemFromOnlineOrderForCart(masterProduct, onlineOrderProduct) {
        return {
            idCart: Math.random(),
            id: masterProduct._id,
            qty: onlineOrderProduct["qty"],
            price: masterProduct.price,
            name: masterProduct.name,
            images: masterProduct.images,
            ingredients: masterProduct.ingredients,
            additionals: [],
            toppings: [],
            notes: onlineOrderProduct["notes"],
        };
    },
    handleOnlineOrderAdditional(masterProduct, onlineOrderProduct, callback) {
        onlineOrderProduct["additionals"]
            .filter((s) => s.selected != "")
            .forEach((adds) => {
            const indexAdds = masterProduct.additionals.findIndex((data) => data["_id"] == adds["id"]);
            if (indexAdds != -1) {
                const addsItem = masterProduct.additionals[indexAdds];
                const indexAddsItem = addsItem.items.findIndex((s) => s.name == adds["selected"]);
                if (indexAddsItem != -1) {
                    const itemSelected = addsItem.items[indexAddsItem];
                    callback("additional", Object.assign(Object.assign({}, itemSelected), { parentId: adds["id"], nameParent: adds["name"] }));
                }
            }
        });
    },
    handleOnlineOrderTopping(masterProduct, onlineOrderProduct, callback) {
        onlineOrderProduct["toppings"].forEach((tops) => {
            const indexTop = masterProduct["toppings"].findIndex((top) => top["_id"] == tops["id"]);
            if (indexTop != -1) {
                callback("topping", masterProduct["toppings"][indexTop]);
            }
        });
    },
    /**
     * This function handles adding additional and toppings. It will handle each case based on the value of from
     */
    handleAddAdditional(from, add, posData) {
        if (posData.titleModal === modalProductOption.Add.title) {
            if (from == updatedProductActionType.Additional) {
                // item.addtionals contains the additional's children with it's parent id attached to it
                if (posData.item.additionals.length > 0) {
                    const exist = posData.item.additionals.filter((item) => item.parentId == add.parentId).length;
                    const existTo = posData.item.additionals.filter((item) => item.code == add.code).length;
                    if (exist === 0 && existTo == 0) {
                        // this runs when adding the second additional and so on
                        posData.item.additionals = [...posData.item.additionals, add];
                    }
                    else if (exist > 0 && existTo == 0) {
                        // this runs when changing an additional's child
                        posData.item.additionals = posData.item.additionals.filter((it) => it["parentId"] != add["parentId"]);
                        posData.item.additionals.push(add);
                    }
                    else {
                        // this runs when unselecting an additional by clicking on the clicked child
                        const removeAdditionalResult = handleRemoveAdditional(updatedProductActionType.Additional, {
                            code: add["code"],
                        }, posData);
                        posData.item = removeAdditionalResult.item;
                    }
                }
                else {
                    // this runs when adding a first additional to the product
                    posData.item.additionals = [...posData.item.additionals, add];
                }
            }
            else {
                if (posData.item.toppings.length > 0) {
                    const exist = posData.item.toppings.filter((item) => item._id == add._id).length;
                    if (exist === 0) {
                        // this runs when adding the second topping and so on
                        posData.item.toppings = [...posData.item.toppings, add];
                    }
                    else {
                        // this runs when unselecting a topping by clicking on the clicked topping
                        const removeToppingResult = handleRemoveAdditional(updatedProductActionType.Topping, {
                            id: add["_id"],
                        }, posData);
                        posData.item = removeToppingResult.item;
                    }
                }
                else {
                    // this runs when adding a first topping to the product
                    posData.item.toppings = [...posData.item.toppings, add];
                }
            }
            return {
                itemResult: posData.item,
                callPromoUsed: false,
            };
        }
        else {
            if (from == updatedProductActionType.Additional) {
                const exist = posData.item.additionals.filter((item) => item.parentId == add.parentId).length;
                const existTo = posData.item.additionals.filter((item) => item.code == add.code).length;
                // when none of the modifier and it's child is selected for the product
                if (exist === 0 && existTo == 0) {
                    posData.item.additionals.push(add);
                    posData.item = posData.item;
                }
                // when user wants to change the selected modifier's child
                else if (exist > 0 && existTo == 0) {
                    const existingIndex = posData.item.additionals.findIndex((it) => it.parentId === add.parentId);
                    if (existingIndex !== -1) {
                        posData.item.additionals.splice(existingIndex, 1, add); // Replace existing item with new one
                        posData.item = posData.item;
                    }
                }
                // when user wants to remove a modifier by clicking the selected modifier's child
                else {
                    const removeAdditionalResult = handleRemoveAdditional(updatedProductActionType.Additional, {
                        code: add["code"],
                    }, posData);
                    posData.item = removeAdditionalResult.item;
                    posData.cart = removeAdditionalResult.cart;
                }
            }
            else {
                const exist = posData.item.toppings.find((item) => item._id == add._id);
                if (!exist) {
                    // add the topping if it is not found/exist
                    posData.item.toppings = [...posData.item.toppings, add];
                    posData.item = posData.item;
                }
                else {
                    // otherwise remove it
                    const removeToppingResult = handleRemoveAdditional(updatedProductActionType.Topping, {
                        id: add["_id"],
                    }, posData);
                    posData.item = removeToppingResult.item;
                    posData.cart = removeToppingResult.cart;
                }
            }
            const cartResult = posData.cart.map((_cart) => {
                if (from == updatedProductActionType.Topping && _cart.idCart == posData.item.idCart) {
                    return Object.assign(Object.assign({}, _cart), { toppings: posData.item.toppings });
                }
                if (from == updatedProductActionType.Additional && _cart.idCart == posData.item.idCart) {
                    return Object.assign(Object.assign({}, _cart), { additionals: posData.item.additionals });
                }
                return _cart;
            });
            return {
                itemResult: posData.item,
                callPromoUsed: true,
                cartResult,
            };
        }
    },
    createItemsSubmit(_cart = []) {
        const itemsOff = _cart.map((item) => {
            return {
                _id: item.id,
                amount: item.qty,
                toppings: item.toppings.map(({ _id, images, name, price }) => ({
                    _id,
                    images,
                    name,
                    price,
                })),
                additionals: item.additionals.length > 0
                    ? [
                        {
                            items: item.additionals.map(({ name, parentId, nameParent, code, price }) => ({
                                name,
                                parentId,
                                nameParent,
                                code,
                                price,
                            })),
                        },
                    ]
                    : [],
                name: item.name,
                price: item.price,
                notes: item.notes,
            };
        });
        return itemsOff;
    },
    isRequiredModifierError(product, additionals = []) {
        // get an array of required modifier ids
        const requiredModifierIds = additionals
            .filter((x) => {
            const hasActiveChild = x.items.find((it) => !!it.active && !!it.activeInPos);
            return x.isRequired && x.status == "ACTIVE" && !!hasActiveChild;
        })
            .map((x) => x._id);
        // check if every single required modifier is selected
        const isRequiredModifierSelected = requiredModifierIds.every((id) => product.additionals.some((additional) => additional.parentId == id));
        if (!isRequiredModifierSelected) {
            globalThis.pushToast("Pilih Modifier Wajib terlebih dahulu.", "error");
            return true;
        }
        return false;
    },
    getPaymentValueMap(paymentValue) {
        return paymentValue == 0 ? 0 : Number.isNaN(paymentValue) ? 0 : this.formatNumber(paymentValue);
    },
    formatStorePin(pin) {
        return pin.replace(/[^a-zA-Z0-9]/g, ""); // will remove non-alphanumeric characters like !@#
    },
    resolveActiveInPosValue(masterPaymentTypeData, storeData) {
        const storePaymentType = storeData.paymentTypes.find((paymentType) => paymentType.code == masterPaymentTypeData.code);
        if (storePaymentType)
            return storePaymentType.activeInPos;
        return false;
    },
    getPaymentTypeNameByCode(paymentTypes, code) {
        var _a, _b;
        return (_b = (_a = paymentTypes.find((payment) => payment.code == code)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : code.toUpperCase();
    },
};
/**
 * This function handles removing additional and toppings. It will handle each case based on the value of from
 */
function handleRemoveAdditional(from, add, posData) {
    if (posData.titleModal === modalProductOption.Add.title) {
        if (from == updatedProductActionType.Additional) {
            posData.item.additionals = posData.item.additionals.filter((item) => item.code !== add.code);
        }
        else {
            posData.item.toppings = posData.item.toppings.filter((item) => item._id !== add.id);
        }
        return {
            item: posData.item,
        };
    }
    else {
        if (from == updatedProductActionType.Additional) {
            for (let itemCart of posData.cart) {
                if (posData.item.idCart === itemCart.idCart) {
                    itemCart.additionals = itemCart.additionals.filter((item) => item.code !== add.code);
                    posData.cart = posData.cart;
                    posData.item = itemCart;
                }
            }
        }
        else {
            for (let itemCart of posData.cart) {
                if (posData.item.idCart === itemCart.idCart) {
                    itemCart.toppings = itemCart.toppings.filter((item) => item._id !== add.id);
                    posData.cart = posData.cart;
                    posData.item = itemCart;
                }
            }
        }
        return {
            item: posData.item,
            cart: posData.cart,
        };
    }
}
function calculateSubtotal(items) {
    return items.reduce((aa, item) => aa +
        (item.price +
            item.additionals.reduce((a, b) => a + b.price, 0) +
            item.toppings.reduce((a, b) => a + b.price, 0)), 0);
}
function getProductBundlePrice(product, toppings, additionals) {
    let toppingPrice = 0;
    let additionalPrice = 0;
    if (toppings) {
        toppingPrice = toppings.reduce((sum, topping) => sum + topping.price, 0);
    }
    if (additionals) {
        additionalPrice = additionals.reduce((sum, add) => sum + add.price, 0);
    }
    return product.price + toppingPrice + additionalPrice;
}
/**
 * This function checks if a product exist in a promo
 */
function isPromoItemProductsExist(products, cartItems) {
    let result = true;
    let indexes = [];
    products.forEach((p) => {
        for (var ii = 0; ii < p.qty; ii++) {
            let foundIndex = cartItems.findIndex((it, index) => it.id == p._id && !indexes.includes(index));
            if (foundIndex == -1) {
                result = false;
                break;
            }
            indexes.push(foundIndex);
        }
    });
    return result;
}
/**
  * This function maps product's data with promo and price information
  */
function getProducstByPromoItemProducts(products, cartItems) {
    let result = [];
    let indexes = [];
    products.forEach((p) => {
        for (var ii = 0; ii < p.qty; ii++) {
            let foundIndex = cartItems.findIndex((it, index) => it.id == p._id && !indexes.includes(index));
            if (foundIndex == -1) {
                result = [];
                break;
            }
            let item = cartItems[foundIndex];
            let subtot = calculateSubtotal([item]);
            item.index = foundIndex;
            item.subtotal = subtot;
            item.additionals = item.additionals.map(({ name, price, code, parentId, nameParent }) => ({
                name,
                price,
                code,
                parentId,
                nameParent,
            }));
            item.toppings = item.toppings.map(({ _id, images, name, price }) => {
                const imageMap = images.map(({ url, _id }) => ({ url, _id }));
                return {
                    _id,
                    images: imageMap,
                    ingredients: [],
                    name,
                    price,
                };
            });
            item.images = item.images.map(({ url, _id }) => ({ url, _id }));
            indexes.push(foundIndex);
            result.push(item);
        }
    });
    return result.map(({ id, name, idCart, qty, price, images, notes, allQty, subtotal, additionals, index, toppings, }) => ({
        id,
        name,
        idCart,
        qty,
        price,
        images,
        notes,
        ingredients: [],
        allQty,
        subtotal,
        additionals,
        index,
        toppings,
    }));
}
function calculatePriceAfterDiscount(type, price, value) {
    if (type == "PERCENT") {
        return (price * (100 - value)) / 100;
    }
    else if (type == "AMOUNT") {
        return price - value;
    }
    else
        return 0;
}
function sortPaymentType(paymentTypeData, sortingIndexData) {
    let unsortedPaymentTypes = paymentTypeData.map((item) => {
        const foundIdx = sortingIndexData.findIndex((code) => code == item.code);
        return Object.assign(Object.assign({}, item), { sortingIdx: foundIdx === -1 ? 999999 : foundIdx });
    });
    // Sort the items based on the sorting index
    return unsortedPaymentTypes.sort((a, b) => {
        let x = a.sortingIdx, y = b.sortingIdx;
        return x == y ? 0 : x > y ? 1 : -1;
    });
}
function sortPaymentTypeByCreatedAt(paymentTypeData) {
    return paymentTypeData.sort((a, b) => {
        if (!a.createdAt)
            return 1; // Move items without createdAt to the end
        if (!b.createdAt)
            return -1; // Move items without createdAt to the end
        return b.createdAt.getTime() - a.createdAt.getTime(); // Sort by createdAt
    });
}
