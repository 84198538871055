export const paymentTypeEnum = {
    Cash: { name: "CASH", code: "CASH" },
    Credit: { name: "CREDIT", code: "CREDIT" },
    Debit: { name: "DEBIT", code: "DEBIT" },
};
export const paymentTypeStatus = {
    Active: "ACTIVE",
    InActive: "INACTIVE",
};
export const excludedPaymentType = ["cod", "qris", "BRI EDC"];
