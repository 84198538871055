<script>
	import { createEventDispatcher } from "svelte";
	import Modal from "../Modal.svelte";
	import { inetStatus, serverStatus } from "../../stores/store";

	const dispatch = createEventDispatcher();
	export let cart;
	export let codeScan;
	export let enableScan;
	let modalScan;

	const hideScan = () => {
		codeScan = "";
		modalScan.close();
	};
	function handleCodeScan(e) {
		const target = e.target;
		if (target.value == "") {
			enableScan = true;
			enableScan = enableScan;
		}
	}

	export function open() {
		modalScan.toggle();
	}

	export function close() {
		modalScan.close();
	}
</script>

<Modal index={20} title="Scan Barcode" bind:this={modalScan} closed on:close={() => hideScan()}>
	<div class="w-full flex items-center justify-center">
		<div class="relative flex align-center w-full mr-3">
			<span
				class="z-10 h-full leading-snug font-normal text-center text-gray-400 absolute bg-transparent text-base flex items-center justify-center w-8 pl-3 py-2"
			>
				<i class="bx bx-scan" />
			</span>
			<input
				bind:value={codeScan}
				type="text"
				on:input={(e) => handleCodeScan(e)}
				autofocus
				placeholder="Enter Transaction Code"
				class="appearance-none block pl-10 pr-10 w-full text-dark-second border dark:text-white dark:bg-dark-third rounded-full py-2 px-3 focus:ring-transparent focus:border-gray-800 outline-none"
			/>
			<span
				on:click={() => {
					enableScan = true;
					codeScan = "";
				}}
				class="z-10 h-full leading-snug font-normal text-center text-gray-400 absolute right-0 bg-transparent text-base flex items-center justify-center w-8 pr-3 py-2 cursor-pointer"
			>
				<i class="bx bx-x" />
			</span>
		</div>

		<div
			class="relative flex items-center justify-center {codeScan.length > 0 && enableScan
				? 'bg-primary'
				: 'bg-gray-400'}  text-white px-4 py-1 rounded-md cursor-pointer"
			on:click={() => {
				if ($inetStatus && $serverStatus && enableScan) {
					if (cart.length == 0) {
						dispatch("submitBarcode");
					} else {
						globalThis.pushToast("Cart should be empty to scan barcode", "error");
					}
				} else if (!enableScan) {
					globalThis.pushToast("Please clear code first", "error");
				} else {
					globalThis.pushToast("You must check internet connection or server status.", "error");
				}
			}}
		>
			<span>Submit</span>
		</div>

		<!-- <video id="video" width="300" height="300" style="border: 1px solid gray" /> -->
	</div>
</Modal>
