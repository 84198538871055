<script>
  import { createEventDispatcher } from "svelte";
  import Switch from "./control/Switch.svelte";

  const dispatch = createEventDispatcher();
  export let data;
  export let disabledAll;
  let switchSelect = false;
  let selectId = [];

  function onClickItem(id) {
    if (!switchSelect) {
      const foundSelectId = selectId.find((selId) => selId == id);
      if (!!foundSelectId) {
        selectId = selectId.filter((selId) => selId != id);
      } else {
        selectId.push(id);
      }
      selectId = selectId;
    } else {
      switchSelect = false;
    }
  }

  const resolveDisable = (item, product) => {
    return disabledAll || !item?.status || (!!product && !product.activeInPos);
  };
</script>

{#each data as item, i}
  <div class="place-items-center">
    <div class="transition">
      <div
        class={`accordion-header-pos-${item._id} bg-gray-100 cursor-pointer border-b relative transition flex justify-between p-3 items-center h-160`}
        on:click={() => onClickItem(item._id)}
      >
        <h3 class="font-medium flex items-center space-x-3">
          <i class={"bx bx-chevron-down"} />
          <span>{item.name}</span>
          {#if item.isRequired}
            <div
              class="text-xs font-bold bg-red-500 px-1 py-0.5 text-white mb-4"
              style="font-size: 10px;"
            >
              WAJIB
            </div>
          {/if}
        </h3>
        <div class="flex items-center justify-center">
          <Switch
            bind:value={item.status}
            checked={item.status}
            notLabel
            textRight={item.status ? "ACTIVE" : "INACTIVE"}
            on:change={() => {
              item.status = !item.status;
              switchSelect = true;
              dispatch("select", item);
            }}
          />
        </div>
      </div>
      {#if selectId.includes(item._id)}
        <div class="accordion-content-pos pt-0 overflow-hidden max-h-0">
          <div class="flex justify-between">
            <div />
            <div class="flex space-x-5 font-medium text-gray-600 pr-2">
              <p>Pos</p>
              <p>Mobile</p>
            </div>
          </div>

          {#if item.products.length > 0}
            {#each item.products as product}
              <div
                class="text-sm flex justify-between font-medium py-2 px-3 text-justify text-gray-600"
              >
                <p>{product.name}</p>
                <div class="flex space-x-4">
                  <Switch
                    bind:value={product.activeInPos}
                    checked={product.activeInPos}
                    name="switch_status_pos_{product._id}"
                    notText
                    on:change={(e) => {
                      if (!resolveDisable(item)) {
                        product.activeInPos = !product.activeInPos;
                        dispatch("posActive", product);
                      }
                    }}
                    disabled={resolveDisable(item)}
                  />
                  <Switch
                    bind:value={product.activeInApp}
                    checked={product.activeInApp}
                    name="switch_status_mobile_{product._id}"
                    notText
                    on:change={(e) => {
                      if (!resolveDisable(item, product)) {
                        product.activeInApp = !product.activeInApp;
                        dispatch("appActive", product);
                      }
                    }}
                    disabled={resolveDisable(item, product)}
                  />
                </div>
              </div>
            {/each}
          {:else}
            <div>Not Available.</div>
          {/if}
        </div>
      {/if}
    </div>
  </div>
{/each}

<style>
  .accordion-content {
    transition:
      max-height 0.3s ease-out,
      padding 0.3s ease;
  }
</style>
